import applyCaseMiddleware from "axios-case-converter"
import * as Sentry from "@sentry/react"

const createHttpClient = () => {
  const csrfToken = document.querySelector("meta[name=csrf-token]")?.content
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  const client = applyCaseMiddleware(axios.create())

  // log errors to sentry
  client.interceptors.response.use(response => response, error => {
    Sentry.setExtra('error', error)
    Sentry.captureException(error)
    return Promise.reject(error)
  })

  return client
}

export default createHttpClient
