import './Nav.scoped.scss'
import useCurrentUser from "Organiser/useCurrentUser";

const Nav = () => {
  const act = useAct()
  const { user } = useCurrentUser();
  const permission = useSelector(s => s.permissions.entities[user?.defaultPermissionId])
  const organisation = useSelector(s => permission ? s.organisations.entities[permission?.organisationId] : {})

  const backMasqueradePath = () => {
    const meta = document.querySelector("[name='back-masquerade-path']");
    return meta ? meta.content : null;
  }
  
  function getInitials() {
    var matches = (user.name || '').match(/\b(\w)/g) // ['J','S','O','N']
    if (!matches) return ''

    var acronym = matches.join('') // JSON
    return acronym.toUpperCase()
  }

  function showMenu() {
    act.local.upsert({ showSidebar: true })
  }

  return (
    <>
      <div className="nav">
        <div className="desktop">
          <div className='search'></div>
          { (user && organisation) && <div className='user'>
            <div className='circle'>{getInitials()}</div>
            <div className='details'>
              <p className='bold'>{user.name}</p>
              <p>{organisation.name}</p>
            </div>
          </div> }
        </div>
        <div className="mobile">
          <div className="logo">
            <p>NEU <span className='blue'>ACTIVATE</span></p>
          </div>

          <a onClick={showMenu}>Show menu</a>
        </div>
      </div>
      {backMasqueradePath() && (
        <div className="border p-2 mb-2 bg-red-200 text-red-500 font-bold">
          You are currently masquerading as another user.{" "}
          <a href={backMasqueradePath()} className="text-red-500 font-bold">
            Click here to go back
          </a>
        </div>
      )}
    </>
  );
};

export default Nav;
