import useCurrentUser from "Organiser/useCurrentUser"
import ServerWaitButton from 'Organiser/components/ServerWaitButton'

export default function ChecklistEmail() {
  const act = useAct()
  const history = useHistory()
  const { user } = useCurrentUser()
  const { body, subject, unsentCount, unstartedCount, isKick, dirty } = useSelector(s => s.email)
  const { checklistId } = useParams()

  useEffect(() => {
    act.checklists.load(checklistId)
  }, [checklistId])

  const { state } = useSelector(s => s.checklists.entities[checklistId] || {})

  // Fetch after state = 'live', so the count is correct
  useEffect(() => {
    if (state !== 'live') return

    act.email.load(checklistId)
    return act.email.clear
  }, [state])

  function send() {
    return act.email.send({ checklistId, body, subject, isKick })
    .then(() => {
      history.push(`/checklists/${checklistId}`)
      act.local.setSnackbar("Your emails are sending")
    })
  }

  const sendCount = isKick ? unstartedCount : unsentCount
  const canSend = body && subject && sendCount > 0

  if (state !== 'live') {
    return <div>Your checklist is being assigned to your users, please hang on until this is complete</div>
  }

  return <div>
    <h2 className="mb-2">Write an email to your members</h2>
    <div className="box p-4">
      { user?.accessLevel === 'admin' &&
        <p className='no-margin-top'>Send an email to all NEU reps. Use your email to explain what this action is, why it’s important and what they’ll need to complete it. It can help to give an idea of when reps should aim to complete the action by. Make sure to proofread your email before hitting send!</p>
      }
      { user?.accessLevel === 'branch_secretary' &&
        <p className='no-margin-top'>Send an email to every rep in your branch. Use your email to explain what this action is, why it’s important and what they’ll need to complete it. It can help to give an idea of when reps should aim to complete the action by. Make sure to proofread your email before hitting send!</p>
      }
      <div className='group'>
        <label>Subject line</label>
        <input value={subject} name='subject' className='wider' type='text' onChange={e => act.email.upsert({ subject: e.target.value })} />
      </div>

      <div className='group'>
        <label>Body</label>
        <textarea value={body} className='wider' name='body' rows={10} onChange={e => act.email.upsert({ body: e.target.value })} />
      </div>

      <div className='group'>
        <label>Send only to people who haven't started the journey?</label>
        <input type='checkbox' checked={isKick} className='wider' name='is_kick' onChange={e => act.email.upsert({ isKick: !isKick })} />
      </div>

      <div className='group'>
        <div className='bold'>This email will be sent to {sendCount} reps.</div>
      </div>

      <ServerWaitButton className='button primary' disabled={!canSend} onClick={send} confirm={true}>Send</ServerWaitButton>
    </div>
  </div>
}