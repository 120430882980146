import React from 'react'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import ActContext from 'hooks/ActContext'
import restClient from 'hooks/restClient'

const JasonProvider = ({ reducers, extraActions, children }) => {
  const store = configureStore({ reducer: reducers })
  const value = { actions: extraActions(store.dispatch, store, restClient) }

  if(!(store && value)) return <div /> // Wait for async fetch of schema to complete

  return <Provider store={store}>
    <ActContext.Provider value={value}>{ children }</ActContext.Provider>
  </Provider>
}

export default JasonProvider

