import { createSlice, createReducer } from "@reduxjs/toolkit"

const { reducer } = createSlice({
  name: "magicLink",
  initialState: { magicLinkCreated: false },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

function actions(dis, store, restClient) {
  function send(email) {
    return restClient
      .post(`/api/magic_links`, { email: email })
      .then(response => {
        dis({ type: "local/upsert", payload: { magicLinkCreated: true } })
      })
  }

  return { send }
}

export { actions, reducer }
