function actions(dis, store, restClient) {
  function loadBy({ checklistId, userId }) {
    return restClient.get(`/api/checklist_users/find_by`, { params: { checklistId, userId } })
      .then(({ data }) => {
        const { checklist, checklistUser } = data

        dis({ type: "checklistUsers/upsert", payload: checklistUser })
        dis({ type: "checklists/upsert", payload: checklist })
      })
  }

  return { loadBy }
}

export { actions }