import { useParams, Link } from "react-router-dom"
import Loading from "Organiser/components/Loading"
import RocketImage from "../../../../images/rocket.png"

export default function ActionComplete() {
  const act = useAct()
  const history = useHistory()
  const { actionId, actionTakerId } = useParams()
  const actionResponse = useEager("actionResponses", actionTakerId)
  const action = useEager("actions", actionId)
  const responseAnswers = useSelector((s) =>
    _.values(s.responseAnswers.entities).filter(
      (a) => a.actionResponseId === parseInt(actionTakerId)
    )
  )
  const hasQuestions = responseAnswers.length > 0
  const fakeResponseMode = useSelector((s) => s.local.fakeResponseMode)

  useEffect(() => {
    act.actions.load(actionId), act.actionResponses.load(actionTakerId)
  }, [actionId, actionTakerId])

  function finishTest() {
    act.local.upsert({ fakeResponseMode: false })
    history.push(`/checklists/${action.checklistId}`)
  }

  return (
    <div>
      <Loading loaded={typeof actionResponse.id !== "undefined"}>
        <h2>
          {action.name}
          <div className="ellipsis wider" />
          <span className="dark-grey">Next Steps</span>
        </h2>
        {fakeResponseMode && (
          <div className="alert">
            Warning: You are testing this survey and responses will not be
            saved.
          </div>
        )}

        {hasQuestions && (
          <>
            <h3 className="no-margin-top">Your answers</h3>
            {responseAnswers.map((responseAnswer) => {
              if (responseAnswer.address) {
                responseAnswer.answerText = JSON.stringify(
                  responseAnswer.address
                )
              }

              return (
                <Answer
                  questionId={responseAnswer.questionId}
                  answerId={responseAnswer.answerId}
                  answers={responseAnswer.answerIds}
                  attributes={responseAnswer}
                />
              )
            })}
            <h3>
              Thank you for completing this action. Your response has been
              recorded. You can access follow up actions by returning to the
              dashboard.
            </h3>
          </>
        )}
        {!hasQuestions && (
          <h2>
            <img src={RocketImage} className="icon" /> Thanks for letting us
            know you've done that!
          </h2>
        )}

        {fakeResponseMode ? (
          <button className="button primary margin-top" onClick={finishTest}>
            Back to my dashboard
          </button>
        ) : (
          <Link className="button primary margin-top" to="/">
            Back to my dashboard
          </Link>
        )}
      </Loading>
    </div>
  )
}
