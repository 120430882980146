import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"

export default () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const act = useAct()
  const history = useHistory()
  const magicLinkCreated = useSelector(s => s.local.magicLinkCreated)
  const [email, setEmail] = useState()

  const onSubmit = ({ email }) => {
    setEmail(email)
    act.magicLink.send(email)
  }

  return (
    <div>
      <h2>Login to NEU Activate</h2>
      <div className="">
        {!magicLinkCreated && (
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <p className='no-margin-top bold'>Enter your email address, and we'll send you a link to sign in.</p>
              <div className="margin-bottom">
                <label>
                  <div>Email</div>
                  <input type='text' {...register("email", { required: true })} />
                </label>
              </div>
              <button type="submit" className="button primary">
                Send me a magic link
              </button>
            </form>
            {errors.email && <div>Email is required</div>}
          </div>
        )}
        {magicLinkCreated && (
          <div>
            Your magic link has been sent! Please check your email at
            <strong> {email}</strong>
          </div>
        )}
      </div>
    </div>
  )
}
