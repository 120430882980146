const useCurrentUser = () => {
  const user = useSelector(s => s.users.entities[window.ENV.CURRENT_USER_ID]);

  if (window.ENV.CURRENT_USER_ID) {
    return { user: user, isLoadingUser: !user };
  } else {
    return { user: null, isLoadingUser: false };
  }
};

export default useCurrentUser;
