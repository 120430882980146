export default ({ api, filters, sorters }) => {
  const [filterState, setFilterState] = useState(
    filters.map(f => ({ name: f.name, value: f.options[0].value }))
  )
  const [order, setOrder] = useState(null)

  const handleFilterChange = (name, e) => {
    const newFilterState = JSON.parse(JSON.stringify(filterState))
    newFilterState.find(f => f.name === name).value = e.target.value
    setFilterState(newFilterState)
  }

  const findFilter = name => {
    return filters.find(f => f.name === name)
  }

  const findFilterState = name => {
    return filterState.find(f => f.name === name)
  }

  useEffect(() => {
    filterState.forEach(state => {
      const filter = findFilter(state.name)
      api.column(filter.column).search(state.value)
    })

    if (order) {
      const parts = order.split('-')
      api.order([parts[0], parts[1]])
    }

    api.draw()
  }, [filterState, order])

  const handleOrderChange = e => {
    setOrder(e.target.value)
  }

  const getFilterCurrentValue = name => {
    return findFilterState(name).value
  }

  console.log({ filters })

  return (
    <div className='filters'>
      <div className='search'>
        {filters.map(filter => (
          <div key={filter.name} className="field">
            <label>
              {filter.name}:
              <select
                value={getFilterCurrentValue(filter.name)}
                onChange={e => {
                  handleFilterChange(filter.name, e)
                }}
              >
                {filter.options.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>
        ))}
      </div>
      <div className='sort'>
        {sorters.length > 0 && (
          <div className="field">
            <label>
              Order:
              <select value={order} onChange={handleOrderChange}>
                {sorters.map(sorter => (
                  <option key={sorter.label} value={`${sorter.column}-${sorter.order}`}>
                    {sorter.label}
                  </option>
                ))}
              </select>
            </label>
          </div>
        )}
      </div>
    </div>
  )
}
