import { MANDATE_LABELS } from 'Organiser/constants'
import RightImage from "../../../../../images/right.png"
import SmileImage from "../../../../../images/smile.png"
import './Checklist.scoped.scss'

export default function Checklist({ checklist, smaller = false }) {
  const act = useAct()
  const history = useHistory()
  const important = checklist?.name.includes('Ballot')

  function begin() {
    if (checklist.started) {
      redirect()
    } else {
      act.checklist.begin(checklist.id).then(() => {
        redirect()
      })
    }
  }

  function redirect() {
    history.push(
      `/checklists/${checklist.id}/actions/${checklist.currentAction.id}/begin`
    )
  }

  return (
    <div key={checklist.id} className={`box outline margin-bottom ${important ? 'important' : ''}`}>
      <div className='content'>
        <div className={`checklist ${smaller ? 'smaller' : ''}`}>
          <div className='icon'>
            <img src={SmileImage} />
          </div>
          <div className='details'>
            <h3 className='no-margin-top no-margin-bottom'>{checklist?.name}</h3>
            { !checklist.finished && (
              <p className="dark-grey half-margin bold">{checklist.currentAction?.name}</p>
            )}
          </div>
          <div className='actions'>
            { !checklist.finished && (
              <button
                type="button"
                className={`button primary ${smaller ? 'smallest' : ''} js-begin-checklist`}
                onClick={begin}
              >
                {checklist?.started ? "Continue" : "Begin"}
              </button>
            ) }
          </div>
        </div>
      </div>
      { checklist.mandatedBy && <div className='footer'>
        <p className='dark-grey'>{MANDATE_LABELS[checklist?.mandatedBy]}</p>
      </div> }
    </div>
  )
}
