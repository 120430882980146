import {
  createEntityAdapter,
  createSlice,
  createReducer,
} from "@reduxjs/toolkit";
import createHttpClient from "Organiser/createHttpClient";
import { v4 as uuidv4 } from 'uuid';
import actionFactory from './actionFactory';

const adapter = createEntityAdapter();

const scriptEditorSlice = createSlice({
  name: 'scriptEditor',
  initialState: {
    adding: false,
    questions: {},
    actionId: null,
    checklistId: null
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
});

function actions(dis, store, restClient) {
  const questionsActions = actionFactory(dis, store, 'question', {}, {
    add: (dis, store, entity) => (data = {}) => {
      const id = uuidv4()
      const { actionId } = data

      const state = store.getState()
      const existingQuestions = _.values(state.questions.entities).filter(q => q.actionId === actionId)
      const questionOrderMax = _.max(_.values(existingQuestions).map(q => q.questionOrder))
      const questionOrder = isNaN(questionOrderMax) ? 0 : questionOrderMax + 1

      dis({ type: `questions/upsert`, payload: { id, questionOrder, ...data } })
      dis({ type: `questions/enforceOrder`, payload: { actionId } })
      dis({ type: `scriptEditor/upsert`, payload: { adding: false } })
    },
    remove: (dis, store, entity) => (id) => {
      const state = store.getState()
      const { actionId } = state.questions.entities[id]

      dis({ type: `questions/remove`, payload: id })
      dis({ type: `questions/enforceOrder`, payload: { actionId } })
    },
    moveOrder: (dis, store, entity) => (id, newOrder) => {
      dis({ type: `questions/moveOrder`, payload: { id, newOrder } })
    }
  })

  const answersActions = actionFactory(dis, store, 'answer', {}, {
    add: (dis, store, entity) => (data = {}) => {
      const id = uuidv4()
      const { questionId } = data

      const state = store.getState()
      const existingAnswers = _.values(state.answers.entities).filter(a => a.questionId === questionId)

      const answerOrderMax = _.max(existingAnswers.map(a => a.answerOrder))
      const answerOrder = isNaN(answerOrderMax) ? 0 : answerOrderMax + 1
      dis({ type: `answers/upsert`, payload: { id, answerOrder, nextQuestionId: null, action: 'next_question', ...data } })
      dis({ type: `answers/enforceOrder`, payload: { questionId } })
    },
    remove: (dis, store, entity) => (id) => {
      const state = store.getState()
      const { questionId } = state.answers.entities[id]

      dis({ type: `answers/remove`, payload: id })
      dis({ type: `answers/enforceOrder`, payload: { questionId } })
    },
    moveOrder: (dis, store, entity) => (id, newOrder) => {
      dis({ type: `answers/moveOrder`, payload: { id, newOrder } })
    }
  })

  function persist(checklistId, actionId) {
    const { questions, answers } = store.getState()
    const filteredQuestions = _.values(questions.entities).filter(q => q.actionId === actionId)
    const questionIds = filteredQuestions.map(q => q.id)
    const filteredAnswers = _.values(answers.entities).filter(a => _.includes(questionIds, a.questionId))

    const params = {
      questions: filteredQuestions,
      answers: filteredAnswers
    }

    return restClient.post(`/api/checklists/${checklistId}/actions/${actionId}/questions`, params).then(({ data: { questions, answers } }) => {
      questionsActions.setAll(questions)
      answersActions.setAll(answers)
    })
  }

  function load(checklistId, actionId) {
    return restClient.get(`/api/checklists/${checklistId}/actions/${actionId}/questions`)
    .then(({ data: { questions, answers } }) => {
      questionsActions.setAll(questions)
      answersActions.setAll(answers)
      dis({ type: `scriptEditor/upsert`, payload: { actionId, checklistId } })
    })
  }

  function setAdding(adding) {
    dis({ type: `scriptEditor/upsert`, payload: { adding } })
  }

  function clear() {
    dis({ type: 'questions/removeAll' })
    dis({ type: 'answers/removeAll' })
  }

  return {
    questions: questionsActions,
    answers: answersActions,
    load,
    persist,
    setAdding,
    clear
  }
}

const reducer = scriptEditorSlice.reducer;
export { actions, reducer };
