function actions(dis, store, restClient) {
  function load(checklistId, actionId, questionId, query, offset) {
    const { local: { organisationId } } = store.getState()

    return restClient.get(`/api/checklists/${checklistId}/actions/${actionId}/results?question_id=${questionId}&query=${query}&offset=${offset}${organisationId ? `&organisation_id=${organisationId}` : ''}`)
  }

  function changeOrganisation(organisationId) {
    dis({ type: "local/upsert", payload: { organisationId } })
  }

  return { load, changeOrganisation }
}

export { actions }