import Table from "Organiser/components/DataTable/Table"
import moment from 'moment';

export default function Takers() {
  const { actionId, checklistId } = useParams()
  const act = useAct()
  const [selectedDate, setSelectedDate] = useState(null)

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date ? moment(date).format('YYYY-MM-DD') : null);
  };

  const handleExport = () => {
    act.dashboard.exportAction(actionId, selectedDate);
  };

  return (
    <div>
      <div className="date-picker-container" style={{ display: 'flex', alignItems: 'center' }}>
        <span>Export answers since (optional):</span>
        <input
          type="date"
          onChange={handleDateChange}
          className="date-picker"
          style={{ marginLeft: '10px' }}
        />
      </div>
      <a className="button smaller secondary" onClick={handleExport}>Export</a>
      <p className='bold'>Below is a list of reps who have taken this action. You can view their answers, and follow up with them.</p>
      <Table table={'actionTakers'} scope={{ actionId, checklistId }} />
    </div>
  );
};
