import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function RingroundSnackbar() {
  const alert = useSelector(s => s.local.alert)
  const act = useAct()

  function handleClose() {
    act.local.upsert({ alert: null })
  }
  if (!alert) return null

  return (
    <Snackbar
      open={true}
      autoHideDuration={alert.persist ? null : alert.duration || 6000}
      onClose={handleClose}
      anchorOrigin={alert.position || { vertical: 'bottom', horizontal: 'center' }}
      style={{ margin: alert.margin || 'inherit' }}
    >
      <Alert onClose={handleClose} severity={alert.severity}>
        {alert.message}
      </Alert>
    </Snackbar>
  )
}
