import Loading from 'Organiser/components/Loading'

export default function Stats({ actionId }) {
  const action = useEager('actions', actionId)

  return (
    <div>
      <Loading loaded={typeof action.name !== 'undefined'}>
        <p>Below is a breakdown of how many activists have started and completed this action. You can drill down into each area to see the number of action takers within it.</p>

        <div className='box'>
          <OrganisationSelector mode={'stats'} actionId={action.id} />
        </div>
      </Loading>
    </div>
  )
}
