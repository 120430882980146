import { fetchUtils, Admin, Resource } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const csrfToken = (document?.querySelector("meta[name=csrf-token]"))?.content

    options.headers.set('X-CSRF-Token', csrfToken);
    return fetchUtils.fetchJson(url, options);
}

import { UserList, UserEdit, UserShow, UserCreate } from "./admin/users";

export default function AdminRoutes() {
  return <Admin
    disableTelemetry
    dataProvider={simpleRestProvider("/admin_api", httpClient)}
  >
    <Resource name="users" list={UserList} edit={UserEdit} show={UserShow} create={UserCreate} />
  </Admin>
}