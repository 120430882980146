import './Answer.scoped.scss';
import Loading from 'Organiser/components/Loading'

const ADDRESS_LABELS = {
  'address1': 'Address 1',
  'address2': 'Address 2',
  'address3': 'Town / City',
  'address4': 'County',
  'address5': 'Country',
  'postcode': 'Postcode'
}

export default function Answer({ reference = 'You', questionId, answerId, answers, attributes }) {
  const question = useSelector(s => s.questions.entities[questionId] || {})
  const answer = useSelector(s => s.answers.entities[answerId] || {})
  const multiAnswers = useSelector(s => {
    if (answers) {
      if (question.hideAnswers) {
        return ['Hidden']
      }
      let collection = []
      console.log(answers, typeof answers)
      answers.forEach(id => {
        if (s.answers.entities[id]) {
          collection.push(s.answers.entities[id])
        }
      })
      return collection
    }
    return []
  })
  const ratings = useSelector(s => {
    if (attributes.ratings && Object.keys(attributes.ratings).length > 0) {
      let collection = []
      for (const key in attributes.ratings) {
        if (s.answers.entities && s.answers.entities[key]) {
          const answer = s.answers.entities[key]
          const label = answer.answer
          if (attributes.ratings[key] === null) {
            collection.push(`${label}: No rating given`)
          } else {
            collection.push(`${label}: ${attributes.ratings[key]}`)
          }
        }
      }
      return collection
    }
    return []
  })

  const noAnswerGiven = _.includes(['instruction', 'download'], question.inputType)
  const address = question.inputType === 'address' ? JSON.parse(attributes.answerText) : {}

  return (
    noAnswerGiven ? null : <Loading width={24} loaded={typeof question.question !== 'undefined'}>
      <div className='answer'>
        {question && <p className='bold'>{question.question}</p>}
        {question.inputType === 'multiple_choice' &&
          <Loading width={24} loaded={typeof answer.answer !== 'undefined'}>
            <p>{question.hideAnswer ? 'Hidden' : answer.answer}</p>
          </Loading>
        }
        {question.inputType === 'multi_select' &&
          <>
            {multiAnswers.map(answer => {
              return <p>{answer.answer}</p>
            })}
          </>
        }
        {question.inputType === 'rating' &&
          <>
            {ratings.map(answer => {
              return <p>{answer}</p>
            })}
            {ratings.length === 0 && <p>No ratings given</p>}
          </>
        }
        {(question.inputType === 'text' || question.inputType === 'number') &&
          <p>{attributes.answerText}</p>
        }
        {question.inputType === 'address' &&
          <p className='margin-top'>
            {Object.keys(address).map(key => {
              return <span><span className='bold'>{ADDRESS_LABELS[key]}:</span> {address[key]}<br /></span>
            })}
          </p>
        }
        {question.inputType === 'date' &&
          <p>{attributes.answerDate}</p>
        }
        {question.inputType === 'file' &&
          <div>
            <p className='margin-top margin-bottom'>{reference} uploaded a file for this question. You can download it below.</p>
            <a className='button smallest primary' target='blank' href={attributes.fileUrl}>Download attachment</a>
          </div>
        }
      </div>
    </Loading>
  );
};
