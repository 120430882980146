import Checklist from "Organiser/views/Dashboards/Partials/Checklist"
import EditableAction from "./EditableAction"
import Loading from "Organiser/components/Loading"
import SmileImage from "images/smile.png"
import WaveImage from "images/wave.png"
import './MyChecklists.scoped.scss'

function excludeBallot(actions) {
  return actions.filter(a => {
    return !window.ENV.RECEIVED_ACTIONS.includes(a.id) && !window.ENV.VOTED_ACTIONS.includes(a.id) && !window.ENV.REQUEST_ACTIONS.includes(a.id)
  })
}

const MODES = {
  'new': 'My Actions',
  'completed': 'Completed Actions',
  'editable': 'Live Actions'
}

const SWITCH_MODES = {
  'New': 'new',
  'Completed': 'completed',
  'Live': 'editable'
}

export default function MyChecklists() {
  const act = useAct()
  const { unstartedChecklists, completedChecklists, editableActions, loaded } = useSelector(s => s.dashboard)

  const [mode, setMode] = useState('new')
  const actionsTitle = MODES[mode]
  const actions = () => {
    if (mode === 'new') {
      return _.orderBy(excludeBallot(_.filter(unstartedChecklists, { started: false, finished: false })), [item => !_.includes(item.name, 'Ballot')], ['asc'])
    } else if (mode === 'completed') {
      return completedChecklists
    } else {
      return editableActions
    }
  }
  const visibleActions = actions()
  const noActions = mode === 'new' && visibleActions.length === 0
  const noCompletedActions = mode === 'completed' && completedChecklists.length === 0
  const followUpTasks = _.filter(unstartedChecklists, { started: true, finished: false })
  const counts = {
    'new': excludeBallot(_.filter(unstartedChecklists, { started: false, finished: false })).length,
    'completed': completedChecklists.length,
    'editable': editableActions?.length || 0
  }

  useEffect(() => {
    loadActions()
  }, [])

  function loadActions() {
    act.dashboard.upsert({ loaded: false })
    act.dashboard.loadMyChecklists().then(() => {
      act.dashboard.upsert({ loaded: true })
    })
  }

  return <div className='row margin-top double'>
    <div className='two-thirds'>
      <div className="box">
      <div className="switch-modes" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        {Object.entries(SWITCH_MODES).map(([text, slug], index, array) => (
          <React.Fragment key={slug}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setMode(slug);
              }}
              style={{
                margin: '0',
                fontWeight: mode === slug ? 'bold' : 'normal',
                color: 'black',
                textDecoration: 'none'
              }}
            >
              <span className="circle">{ counts[slug] }</span>
              {text}
            </a>
            {index < array.length - 1 && <span className="ellipsis" style={{ margin: '0 15px' }}></span>}
          </React.Fragment>
        ))}
      </div>

        <h3 className="no-margin-top">{ actionsTitle }</h3>
        { noActions && <p className='no-margin-top'><img src={SmileImage} className='icon' /> You've not got any new actions to do right now, nice one!</p> }
        { noCompletedActions && <p className='no-margin-top'>You've not completed any actions yet.</p> }
        <Loading loaded={loaded}>
          { mode !== 'editable' && visibleActions.map(c => <Checklist key={c.id} checklist={c} />) }
          { mode === 'editable' && visibleActions.map(c =>
            <EditableAction key={c.id} action={c} />
          ) }
        </Loading>
      </div>
    </div>

    <div className='third padding-left margin-top-mobile'>
      <p className='no-margin-top'>FOLLOW-UP TASKS</p>
      { followUpTasks.map(c => <Checklist key={c.id} checklist={c} smaller={true} />) }
      { followUpTasks.length === 0 && <p>You've not got any follow-up tasks to do right now!</p> }
    </div>
  </div>
}