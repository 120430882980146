import useCurrentUser from "Organiser/useCurrentUser"
import Answers from './Partials/Answers'
import Stats from './Partials/Stats'
import Takers from './Partials/Takers'
import Loading from 'Organiser/components/Loading'
import './Results.scoped.scss'
import { useEffect } from 'react'

export default function ActionResults() {
  const act = useAct()
  const { actionId, checklistId } = useParams()
  const { organisationId } = useSelector(s => s.local)
  const tabs = ['Answers', 'Stats', 'Takers']
  const [tab, setTab] = useState('Answers')
  const action = useEager('actions', actionId)
  const { user } = useCurrentUser()
  const permission = useEager('permissions', user?.defaultPermissionId)
  const organisation = useEager('organisations', permission?.organisationId)

  useEffect(() => {
    act.checklists.load(checklistId, null, true)
  }, [checklistId])

  useEffect(() => {
    if (typeof organisation.id !== 'undefined') {
      act.local.upsert({ organisationId: organisation.id })
    }
  }, [JSON.stringify(organisation)])

  function changeOrganisation(newId) {
    act.results.changeOrganisation(newId)
  }

  return (
    <div>
      <Loading loaded={typeof action.name !== 'undefined'}>
        <h3 className='dark-grey'>{action.name} <div className='ellipsis'></div> stats</h3>

        <div className='tabs margin-bottom double'>
          { tabs.map((label) => {
            return <a onClick={() => setTab(label)} className={`tab ${label === tab ? 'selected' : ''}`}>{label}</a>
          })}
        </div>
        
        <div className='filter'>
          <p className='strong'>Viewing results as:</p>
          <AsyncSelect
            entity='organisation'
            value={ organisationId }
            onChange={v => changeOrganisation(v)}
          />
        </div>

        { tab === 'Answers' && <Answers actionId={actionId} /> }
        { tab === 'Stats' && <Stats actionId={actionId} /> }
        { tab === 'Takers' && <Takers actionId={actionId} /> }
      </Loading>
    </div>
  );
};
