import Select from 'react-select'
import detectCycle from './detectCycle.js'
import useCurrentUser from "Organiser/useCurrentUser"
import './AdvancedSettings.scoped.scss'

function buildDescription(action) {
  if (action === 'next_question') {
    return null
  } else if (action === 'go_to_question') {
    return 'This answer moves reps to another question.'
  } else if (action === 'move_action') {
    return 'This answer gives reps a follow-on action to complete.'
  }
  return null
}

function buildOptionTree(canBranchActions, questions, actions) {
  var tree = [
    {
      label: "Basic",
      disabled: true,
      options: [
        { value: { action: 'next_question', nextQuestionId: null, nextActionId: null }, label: 'Next question' }
      ]
    },
    {
      label: "Move to another question",
      disabled: true,
      options: questions
    }
  ]
  if (canBranchActions) {
    tree.push({
      label: "Move to another action",
      disabled: true,
      options: actions
    })
  }
  return tree
}

function deepSearch(options, value, tempObj = {}) {
  if (options && value != null) {
    options.find((node) => {
      console.log('on node', node.value, value, node.value === value)
      if (_.isEqual(node.value, value)) {
        tempObj.found = node
        return node
      }
      return deepSearch(node.options, value, tempObj)
    })
    if (tempObj.found) {
      return tempObj.found
    }
  }
  return undefined
}

export default function AdvancedSettings({ answerId }) {
  const act = useAct()
  const { user } = useCurrentUser()
  const actionId = useSelector(s => s.scriptEditor.actionId)
  const questions = useSelector(s => _.values(s.questions.entities))
  const answers = useSelector(s => _.values(s.answers.entities))
  const actions = useSelector(s => _.values(s.actions.entities))
  const { questionId, action, nextQuestionId, nextActionId } = useSelector(s => s.answers.entities[answerId])
  const [expanded, setExpanded] = useState(false)
  const description = buildDescription(action)
  const hasDescription = description !== null
  const canBranchActions = ["admin", "national"].includes(user?.accessLevel)

  // build our option array of what we can do next
  const moveableQuestions = questions.map(q => {
    // Pass in answers, 'as if' this one was chosen
    const hypotheticalAnswers = _.unionBy([{ answerId, questionId, nextQuestionId: q.id, action: 'go_to_question' }], answers, 'id')
    const willCreateCycle = detectCycle(questions, hypotheticalAnswers)

    return { value: { action: 'go_to_question', nextQuestionId: q.id, nextActionId: null }, label: q.question, disabled: willCreateCycle }
  })
  const moveableActions = actions.map(a => {
    return { value: { action: 'move_action', nextQuestionId: null, nextActionId: parseInt(a.id) }, label: a.name, disabled: parseInt(a.id) === parseInt(actionId) }
  })
  const options = buildOptionTree(canBranchActions, moveableQuestions, moveableActions)

  return <div className='advanced'>
    { !expanded &&
      <p className='smaller'>
        { hasDescription && <span>{description}</span> }
        <a className={`button naked link blue ${hasDescription ? 'margin-left' : ''}`} onClick={() => setExpanded(true)}>{ hasDescription ? 'Change' : 'Add effect'}</a>
      </p>
    }
    { expanded && 
      <>
        <p>After this:</p>
        <div className='select'>
          <Select
            defaultValue={ deepSearch(options, { action, nextQuestionId, nextActionId }) }
            options={options}
            isOptionDisabled={o => o.disabled}
            onChange={option => act.scriptEditor.answers.upsert(answerId, option.value)}
          />
        </div>
      </>
    }
  </div>     
}