import { ChecklistColumns, ChecklistHeaders, ChecklistFilters, ChecklistSorters, ChecklistOrder } from 'Organiser/components/DataTable/definitions/checklists'
import { ActionColumns, ActionHeaders, ActionFilters, ActionSorters, ActionOrder } from 'Organiser/components/DataTable/definitions/actions'
import { ActionTakerColumns, ActionTakerHeaders, ActionTakerFilters, ActionTakerSorters, ActionTakerOrder } from 'Organiser/components/DataTable/definitions/actionTakers'

const URLS = {
  checklists: '/api/checklists/table',
  actions: '/api/my/actions',
  actionTakers: '/api/action_responses/table'
}

const COLUMNS = {
  checklists: ChecklistColumns,
  actions: ActionColumns,
  actionTakers: ActionTakerColumns
}

const HEADERS = {
  checklists: ChecklistHeaders,
  actions: ActionHeaders,
  actionTakers: ActionTakerHeaders
}

const FILTERS = {
  checklists: ChecklistFilters,
  actions: ActionFilters,
  actionTakers: ActionTakerFilters
}

const SORTERS = {
  checklists: ChecklistSorters,
  actions: ActionSorters,
  actionTakers: ActionTakerSorters
}

const ORDERS = {
  checklists: ChecklistOrder,
  actions: ActionOrder,
  actionTakers: ActionTakerOrder
}

export { COLUMNS, HEADERS, URLS, FILTERS, SORTERS, ORDERS }