import Loading from 'Organiser/components/Loading'
import Answer from 'Organiser/components/Answer'
import useCurrentUser from "Organiser/useCurrentUser"
import './View.scoped.scss'

export default function UserView() {
  const act = useAct()
  const { userId } = useParams()
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (userId) {
        act.users.load(userId).then(({ data }) => {
            console.log(data)
            setUser(data.user)
        })
    }
  }, [userId])

  return (
    <div>
      <Loading loaded={typeof user !== 'undefined'}>
        <h3 className='dark-grey'>
            {user?.name}
            <div className='ellipsis wider' />
            <span className='dark-grey'>{user?.organisationName}</span>
        </h3>
        <p className='bold'>Email: {user?.email}</p>

        <h3>Checklists (live)</h3>
        <div className='checklists'>
            {user?.checklists?.map((checklist) => ( 
                <div className={`checklist ${checklist.isFinished ? 'done' : ''}`} key={checklist.id}>
                    <p className='bold name'>{checklist.name}</p>
                    <p className='status'>{checklist.isFinished ? 'Finished' : 'Not finished'}</p>
                    { !checklist.isFinished && <p><span className='bold'>Current action: </span>{checklist.currentActionName}</p> }
                </div>
            ))}
        </div>
      </Loading>
    </div>
  );
};
