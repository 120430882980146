function actions(dis, store, restClient) {
  function load(id, render = false) {
    return restClient.get(`/api/actions/${id}${render ? '?render=true' : ''}`)
      .then(({ data }) => {
        const { checklist, action, questions, answers } = data

        dis({ type: "checklist/upsert", payload: checklist })
        dis({ type: "action/upsert", payload: action })
        answers.forEach(a => dis({ type: "answers/upsert", payload: a }))
        questions.forEach(q => dis({ type: "questions/upsert", payload: q }))
      })
  }

  function exportTrackingLinks(actionId) {
    return restClient.post(`/api/actions/${actionId}/export_live_tracking_links`)
    .then(({ data }) => {
      const { password } = data
      dis({ type: 'local/upsert', payload: {
        alert: {
          persist: true,
          message: `The live tracking links for this action are being exported. You will receive an email when this is complete. Use this password to open the attached file: ${password}`
        }
      }})
    })
    .catch(() => {
      dis({ type: 'local/upsert', payload: {
        alert: {
          persist: true,
          severity: 'error',
          message: `Something went wrong and the live tracking links couldn't be exported`
        }
      }})
    })
  }

  function moveUp(checklistId, actionId) {
    return restClient.post(`/api/checklists/${checklistId}/actions/${actionId}/move_up`)
      .then(({ data }) => {
        const { actions } = data
        actions.forEach(a => dis({ type: "actions/upsert", payload: a }))
      })
  }

  function moveDown(checklistId, actionId) {
    return restClient.post(`/api/checklists/${checklistId}/actions/${actionId}/move_down`)
      .then(({ data }) => {
        const { actions } = data
        actions.forEach(a => dis({ type: "actions/upsert", payload: a }))
      })
  }

  return { load, moveUp, moveDown, exportTrackingLinks };
}

export { actions }