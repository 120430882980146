import { useEffect } from 'react'
import { COLUMNS, HEADERS, URLS, FILTERS, SORTERS, ORDERS } from './constants'
import Filters from './Filters'

const Table = ({ table, scope = {} }) => {
  const ref = useRef(null)
  const [api, setApi] = useState(null)
  const columnHeaders = HEADERS[table]
  const filters = FILTERS[table]
  const sorters = SORTERS[table]

  useEffect(() => {
    const query = _.map(scope, (v, k) => {
      return `${_.snakeCase(k)}=${v}`
    }).join('')

    const url = URLS[table] + '?' + query

    const dataTable = window.$(ref.current).dataTable({
      "fnDrawCallback": function(oSettings) {
        const totalPages = oSettings._iDisplayLength === -1 ?
                          0 : Math.ceil( oSettings.fnRecordsDisplay() / oSettings._iDisplayLength)
        if (totalPages <= 1) {
          const finder = ["#", oSettings.sInstance, "_wrapper", ' ', '.dataTables_paginate'].join('');
          $(finder).hide();
        }
      },
      processing: false,
      serverSide: true,
      lengthChange: false,
      info: false,
      paging: true,
      ajax: {
        url
      },
      pagingType: 'full_numbers',
      columns: COLUMNS[table],
      oLanguage: {
        sSearch: ""
      },
      order: ORDERS[table] || []
    })

    setApi(dataTable.api())
  }, [])

  return <>
    {api &&
      <Filters
        filters={filters}
        sorters={sorters}
        api={api}
      />
    }
    <div className="datatable">
      <table ref={ref}>
        <thead>
          <tr>
            { columnHeaders.map((header) => { return <th>{header}</th> }) }
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
    </div>
  </>
}

export default Table
