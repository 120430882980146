import { MANDATE_LABELS } from 'Organiser/constants'
import RightImage from "../../../../../images/right.png"
import SmileImage from "../../../../../images/smile.png"
import './Checklist.scoped.scss'

export default function EditableAction({ action, smaller = false }) {
  const act = useAct()
  const history = useHistory()

  function begin() {
    history.push(
      `/checklists/${action.checklistId}/actions/${action.actionId}/begin`
    )
  }

  return (
    <div key={action.actionId} className={`box outline margin-bottom`}>
      <div className='content'>
        <div className={`checklist ${smaller ? 'smaller' : ''}`}>
          <div className='icon'>
            <img src={SmileImage} />
          </div>
          <div className='details'>
            <h3 className='no-margin-top no-margin-bottom'>{action?.name}</h3>
          </div>
          <div className='actions'>
            <button
                type="button"
                className={`button primary ${smaller ? 'smallest' : ''} js-begin-checklist`}
                onClick={begin}
            >
                Edit answers
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
