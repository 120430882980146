import useCurrentUser from "Organiser/useCurrentUser"
import MyChecklists from 'Organiser/views/Dashboards/Partials/MyChecklists'
import BallotActions from 'Organiser/views/Dashboards/Partials/BallotActions'
import LiveTrackedActions from 'Organiser/views/Dashboards/Partials/LiveTrackedActions'
import WaveImage from "images/wave.png"

export default function DashboardActivist() {
  const { user } = useCurrentUser()
  const permission = useEager('permissions', user?.defaultPermissionId)
  const organisation = useEager('organisations', permission?.organisationId)
  const address = _.compact([user?.extraData?.address_1, user?.extraData?.address_2]).join(', ')
  const demo = window.ENV.DEMO

  return (
    <div>
      <h2>
        <img className='icon' src={WaveImage} /> {user.name}, welcome back to {organisation.name}
      </h2>

      { user.newDashboard && <>
        <div className="box">
          <h3 className="no-margin-top">
          Welcome to { demo ? 'UnionLab Ballot' : 'NEU Activate' }.
          </h3>
          <p>Welcome to { demo ? 'UnionLab Ballot' : 'NEU Activate' }, our digital ballot platform.</p>
          <p>You will find any ballot actions you need to complete below.</p>
          { !demo && <p>For questions relating to the re-ballot please read our FAQs and other info here: <a href="https://neu.org.uk/payup" target="_blank">https://neu.org.uk/payup</a></p> }

          <h3>Your information</h3>
          <p><span className='bold'>Your address: </span> { address }</p>
          <p><span className='bold'>Email: </span> { user?.email }</p>
          <p><span className='bold'>Phone number: </span> { user?.phoneNumber }</p>
          <p><span className='bold'>School: </span> { organisation?.name }</p>

          <p>Any of these details incorrect? Update them on <a href='https://my.neu.org.uk/login' target='_blank'>myNEU</a>.</p>
        </div>

        <BallotActions />
        <MyChecklists />
      </> }

      { !user.newDashboard && <>
        <div className="box">
          <h3 className="no-margin-top">
          Welcome to NEU Activate.
          </h3>
          <p>Welcome to NEU Activate, our digital ballot platform.</p>
          <p>You will find any ballot actions you need to complete below.</p>
          <p>For questions relating to the re-ballot please read our FAQs and other info here: <a href="https://neu.org.uk/payup" target="_blank">https://neu.org.uk/payup</a></p>
        </div>

        { user.accessLevel === 'activist' && <LiveTrackedActions /> }
        <MyChecklists />
      </> }
    </div>
  )
}
