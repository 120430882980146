import Loader from "react-loader-spinner";

export default function ServerWaitButton ({ className, onClick, children, disabled, message = null, loaderHeight = 16, loaderColor='#00BFFF', confirm = false }) {
  const act = useAct()
  const saving = useSelector(s => s.local.saving)
  const [me, setMe] = useState(false)
  const [confirmStage, setConfirmStage] = useState(false)

  function doAction() {
    if (confirm && !confirmStage) {
      setConfirmStage(true)
      return
    }

    if (!saving) {
      act.local.upsert({ saving: true })
      setMe(true)
      // the function onClick must return a promise.
      onClick().then(() => {
        act.local.upsert({ saving: false })
        setMe(false)
        setConfirmStage(false)
      }).catch(() => {
        act.local.upsert({ saving: false })
        setMe(false)
        setConfirmStage(false)
      })
    }
  }

  function onYes() {
    setConfirmStage(false)
    doAction()
  }

  function onNo() {
    setConfirmStage(false)
  }

  if (confirmStage) return <Confirm onYes={onYes} onNo={onNo} className={className} />

  return <>
    <button className={`${className} loader`} onClick={doAction} type='button' disabled={disabled || (me && saving)}>
      { !saving && children } { (me && saving) && <Loader
        type="Bars"
        color={loaderColor}
        height={loaderHeight}
        width={loaderHeight}
        timeout={15000}
      /> }
    </button>
    { me && saving && message && <div><p className='no-margin-bottom error'>{message}</p></div> }
  </>
}

function Confirm({ onYes, onNo, className }) {
  return <div>
    <div>Are you sure?</div>
    <button onClick={onYes} className={className}>Yes</button>
    <button onClick={onNo} className={className}>No</button>
  </div>
}