export default function userGroupsForAccessLevel(accessLevel) {
  const userGroups = [
    { slug: 'activists', label: 'Reps' },
    { slug: 'members_with_activists', label: 'Members with Reps' },
    { slug: 'members_without_activists', label: 'Members without Reps' }
  ]
  if (_.includes(['admin', 'super_admin', 'regional'], accessLevel)) {
    userGroups.unshift({ slug: 'branch_secretaries', label: 'Branch Secretaries' })
  }
  if (_.includes(['admin', 'super_admin'], accessLevel)) {
    userGroups.unshift({ slug: 'regional_admins', label: 'Regional Admins' })
  }

  return userGroups
}