import { createSlice } from "@reduxjs/toolkit"
import { actions as actionResponsesActions } from 'hooks/actionResponsesActions'

const actionSlice = createSlice({
  name: "action",
  initialState: {
    actionResponseId: null,
    fakeAnswers: {

    }
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
    addFakeAnswer: (s, a) => {
      const { questionId, answer } = a.payload
      s.fakeAnswers[questionId] = answer
    }
  },
})

function actions(dis, store, restClient) {
  const actionResponses = actionResponsesActions(dis, store, restClient)

  function checkFakeResponseMode() {
    return store.getState().local.fakeResponseMode
  }

  function beginAnswering({ checklistId, actionId }) {
    if (checkFakeResponseMode()) {
      dis({
        type: "action/upsert",
        payload: { actionResponseId: -1 },
      })
      return Promise.resolve()
    }

    return restClient.post(
      `/api/checklists/${checklistId}/actions/${actionId}/action_responses`
    )
      .then(response => {
        dis({
          type: "action/upsert",
          payload: { actionResponseId: response.data.id },
        })
        dis({ type: "actionResponses/upsert", payload: { id: response.data.id, completedAt: null } })
        return response
      })
  }

  function reloadActionResponse() {
    const actionResponseId = store.getState().action.actionResponseId
    actionResponses.load(actionResponseId)
  }

  function answerMultipleChoiceQuestion({
    checklistId,
    actionId,
    questionId,
    answerId,
  }) {
    if (checkFakeResponseMode()) {
      dis({
        type: "responseAnswers/upsert",
        payload: { id: questionId, questionId, answerId, actionResponseId: -1 }
      })
      actionResponses.fakeNextQuestion(answerId, actionId)
      return Promise.resolve()
    }

    return restClient.post(
      `/api/checklists/${checklistId}/actions/${actionId}/response_answers`,
      {
        questionId,
        answerId,
      }
    )
      .then(reloadActionResponse)
  }

  function answerMultiSelectQuestion({
    checklistId,
    actionId,
    questionId,
    answers,
  }) {
    if (checkFakeResponseMode()) {
      dis({
        type: "responseAnswers/upsert",
        payload: { id: questionId, questionId, answerIds: answers, actionResponseId: -1 }
      })
      const answerPicked = (answers && answers.length > 0) ? answers[0] : null
      actionResponses.fakeNextQuestion(answerPicked, actionId)
      return Promise.resolve()
    }

    return restClient.post(
      `/api/checklists/${checklistId}/actions/${actionId}/response_answers`,
      {
        questionId,
        answers,
      }
    )
      .then(reloadActionResponse)
  }

  function answerRatingQuestion({
    checklistId,
    actionId,
    questionId,
    answers,
  }) {
    if (checkFakeResponseMode()) {
      dis({
        type: "responseAnswers/upsert",
        payload: { id: questionId, questionId, answerIds: answers, actionResponseId: -1 }
      })
      const answerPicked = (answers && answers.length > 0) ? answers[0] : null
      actionResponses.fakeNextQuestion(answerPicked, actionId)
      return Promise.resolve()
    }

    return restClient.post(
      `/api/checklists/${checklistId}/actions/${actionId}/response_answers`,
      {
        questionId,
        ratings: answers,
      }
    )
      .then(reloadActionResponse)
  }

  function answerDownloadQuestion({
    checklistId,
    actionId,
    questionId
  }) {
  }

  function answerTextQuestion({
    checklistId,
    actionId,
    questionId,
    answerText,
  }) {
    if (checkFakeResponseMode()) {
      dis({
        type: "responseAnswers/upsert",
        payload: { id: questionId, questionId, answerText, actionResponseId: -1 }
      })
      actionResponses.fakeNextQuestion(null, actionId)
      return Promise.resolve()
    }

    return restClient.post(
      `/api/checklists/${checklistId}/actions/${actionId}/response_answers`,
      {
        questionId,
        answerText,
      }
    )
      .then(reloadActionResponse)
  }

  function answerDateQuestion({
    checklistId,
    actionId,
    questionId,
    answerDate,
  }) {
    if (checkFakeResponseMode()) {
      dis({
        type: "responseAnswers/upsert",
        payload: { id: questionId, questionId, answerDate, actionResponseId: -1 }
      })
      actionResponses.fakeNextQuestion(null, actionId)
      return Promise.resolve()
    }

    return restClient.post(
      `/api/checklists/${checklistId}/actions/${actionId}/response_answers`,
      {
        questionId,
        answerDate,
      }
    )
      .then(reloadActionResponse)
  }

  function answerAddressQuestion({
    checklistId,
    actionId,
    questionId,
    address
  }) {
    if (checkFakeResponseMode()) {
      dis({
        type: "responseAnswers/upsert",
        payload: { id: questionId, questionId, address, actionResponseId: -1 }
      })
      actionResponses.fakeNextQuestion(null, actionId)
      return Promise.resolve()
    }

    return restClient.post(
      `/api/checklists/${checklistId}/actions/${actionId}/response_answers`,
      {
        questionId,
        address,
      }
    )
      .then(reloadActionResponse)
  }

  function answerFileQuestion({
    checklistId,
    actionId,
    questionId,
    answerFile,
  }) {
    if (checkFakeResponseMode()) {
      dis({
        type: "responseAnswers/upsert",
        payload: { id: questionId, questionId, actionResponseId: -1 }
      })
      actionResponses.fakeNextQuestion(null, actionId)
      return Promise.resolve()
    }

    const formData = new FormData()
    formData.append("answerFile", answerFile[0])
    formData.append("questionId", questionId)

    return restClient.post(
      `/api/checklists/${checklistId}/actions/${actionId}/response_answers`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then(reloadActionResponse)
  }

  function startFake() {
    dis({ type: 'local/upsert', payload: { fakeResponseMode: true } })
    dis({ type: 'actionResponses/setAll', payload: [] })
    dis({ type: 'responseAnswers/setAll', payload: [] })
  }

  return {
    beginAnswering,
    answerMultipleChoiceQuestion,
    answerMultiSelectQuestion,
    answerRatingQuestion,
    answerDownloadQuestion,
    answerTextQuestion,
    answerDateQuestion,
    answerFileQuestion,
    answerAddressQuestion,
    startFake
  };
}

const reducer = actionSlice.reducer
export { actions, reducer }
