import "./Sidebar.scoped.scss"
import navImage from "../../../images/neu.png"
import { Link, useLocation } from "react-router-dom"
import useCurrentUser from "Organiser/useCurrentUser"
var mobile = require("is-mobile")

export default function Sidebar() {
  const act = useAct()
  const location = useLocation()
  const { user, isLoadingUser } = useCurrentUser()
  const { showSidebar, enableForums } = useSelector((s) => s.local)
  const accessLevel = user?.accessLevel
  function visibilityClasses() {
    if (showSidebar === null) {
      return "media-query"
    } else {
      return showSidebar ? "open" : "closed"
    }
  }

  useEffect(() => {
    if (mobile()) {
      act.local.upsert({ showSidebar: false })
    }
  }, [location])

  const getCSRFToken = () => {
    const csrfToken = document.querySelector("[name='csrf-token']")

    if (csrfToken) {
      return csrfToken.content
    } else {
      return null
    }
  }

  const afterSignOut = () => {
    window.location = "/"
  }

  const handleSignOutClick = () => {
    const url = "/users/sign_out"
    const options = {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": getCSRFToken(),
      },
    }
    fetch(url, options)
      .then(afterSignOut)
      .catch((error) => console.log(error))
  }

  return (
    <div className={`sidebar ${visibilityClasses()}`}>
      {window.ENV.DEMO && (
        <h1 style={{ color: "white", marginLeft: "25px" }}>Ballot</h1>
      )}

      {!window.ENV.DEMO && (
        <div className="h-24 logo">
          <img src="/payup.jpg" />
        </div>
      )}

      {!isLoadingUser && user && (
        <>
          <Link to="/" className="link">
            Home
          </Link>

          {["activist"].includes(accessLevel) && (
            <Link to="/actions" className="link">
              Actions
            </Link>
          )}

          {["admin", "national"].includes(accessLevel) && (
            <Link to="/checklists" className="link">
              Journeys
            </Link>
          )}

          {enableForums && (
            <a href="/forum" className="link">
              Forum
            </a>
          )}

          {["admin", "national"].includes(accessLevel) && (
            <Link to="/rep_dashboards" className="link">
              Rep Dashboards
            </Link>
          )}

          {["regional"].includes(accessLevel) && (
            <Link to="/checklists" className="link">
              Surveys
            </Link>
          )}

          <a className="link" onClick={handleSignOutClick}>
            Sign Out
          </a>
        </>
      )}
    </div>
  )
}
