function actions(dis, store, restClient) {
  function load(id, full = false) {
    return restClient.get(`/api/actions/${id}/statuses${full ? '?full_details=1' : ''}`)
      .then(({ data }) => {
        dis({ type: "local/upsert", payload: {
          dashboardColumns: data.columns,
          dashboardName: data.dashboardName,
          enableConversations: data.enableConversations,
          showStatus: data.showStatus,
          statusLabel: data.statusLabel
        } })
        
        if (full) {
          dis({ type: "local/upsert", payload: data })
        } else {
          let current = store.getState().local.statuses
          let updated = {}
          for (var id in data.statuses) {
            let updatedRecord = { ...current[id], ...data.statuses[id] }
            updated[id] = updatedRecord
          }
          dis({ type: "local/upsert", payload: { statuses: updated } })
        }
      })
  }

  function downloadPdf(id) {
    return restClient.get(`/api/actions/${id}/statuses_pdf`)
  }

  return { load, downloadPdf }
}

export { actions }