import React from 'react';
import { useForm } from 'react-hook-form';

const RatingQuestion = ({ question, answers, onSubmit }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleSubmitRatingAnswer = (data) => {
        const formattedData = Object.entries(data).reduce((acc, [key, value]) => {
            if (key.startsWith('rating_')) {
                acc[key.replace('rating_', '')] = parseInt(value, 10);
            }
            return acc;
        }, {});
        onSubmit(formattedData);
    };

    return (
        <form onSubmit={handleSubmit(handleSubmitRatingAnswer)}>
            <table className="w-full border-collapse" style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th className="p-2"></th>
                        {[1, 2, 3, 4, 5].map(rating => (
                            <th key={rating} className="p-2" style={{ textAlign: 'center' }}>{rating}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {answers.map(answer => (
                        <tr key={answer.id}>
                            <td className="p-2" style={{ textAlign: 'left', minWidth: '40%' }}>{answer.answer}</td>
                            {[1, 2, 3, 4, 5].map(rating => (
                                <td key={rating} className="p-2 text-center" style={{ textAlign: 'center' }}>
                                    <input
                                        type="radio"
                                        name={`rating_${answer.id}`}
                                        value={rating}
                                        {...register(`rating_${answer.id}`, { required: true })}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {Object.keys(errors).length > 0 && (
                <div className="text-red-500 mt-2">Please rate all answers</div>
            )}
            <button className="button primary margin-top mt-4" type="submit">
                Next
            </button>
        </form>
    );
};

export default RatingQuestion;