import { createSlice } from "@reduxjs/toolkit";

const checklistSlice = createSlice({
  name: "checklist",
  initialState: {},
  reducers: {},
});

function actions(dis, store, restClient) {
  function begin(checklistId) {
    return restClient.post(`/api/checklists/${checklistId}/checklist_users`)
      .then(response => {
        dis({
          type: "local/upsert",
          payload: { redirectToChecklistId: checklistId },
        });
      });
  }

  function close(checklistId) {
    return restClient.post(`/api/checklists/${checklistId}/close`);
  }

  function archive(checklistId) {
    return restClient.post(`/api/checklists/${checklistId}/archive`);
  }

  function archive(checklistId) {
    return restClient.post(`/api/checklists/${checklistId}/archive`);
  }

  function create(data, childIds) {
    return restClient.post(`/api/checklists`, { ...data, childIds })
      .then(response => {
        dis({
          type: "checklist/setCurrentChecklistId",
          payload: { currentChecklistId: response.data.id },
        });
        return response;
      });
  }

  function duplicate(checklistId) {
    return restClient.post(`/api/checklists/${checklistId}/duplicate`)
  }

  function goLive(checklistId) {
    return restClient.post(`/api/checklists/${checklistId}/go_live`);
  }

  function save(checklistId, data, childIds) {
    return restClient.post(`/api/checklists/${checklistId}`, { ...data, childIds });
  }

  return { begin, close, create, goLive, save, archive, duplicate };
}

const reducer = checklistSlice.reducer;
export { actions, reducer };
