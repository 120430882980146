import Loading from 'Organiser/components/Loading'
import Answer from 'Organiser/components/Answer'
import useCurrentUser from "Organiser/useCurrentUser"

export default function ActionTaker() {
  const act = useAct()
  const { actionTakerId } = useParams()
  const { user: currentUser } = useCurrentUser()
  const actionResponse = useEager('actionResponses', actionTakerId)
  const action = useEager('actions', actionResponse.actionId)
  const actionId = action.id
  const checklist = useEager('checklists', action.checklistId)
  const user = useEager('users', actionResponse.userId)
  const responseAnswers = useSelector(s => _.values(s.responseAnswers.entities).filter(a => a.actionResponseId === parseInt(actionResponse.id)))
  const viewingOwnAction = parseInt(actionResponse.userId) === parseInt(currentUser.id)
  const reference = viewingOwnAction ? 'You' : user.name

  useEffect(() => {
    if (actionId) act.actions.load(actionId)
    if (actionTakerId) act.actionResponses.load(actionTakerId)
  }, [actionId, actionTakerId])

  return (
    <div>
      <Loading loaded={typeof actionResponse.id !== 'undefined'}>
        <h3 className='dark-grey'>{checklist.name} <div className='ellipsis'></div> {action.name}</h3>
        { !viewingOwnAction && <>
          <h2>{user.name}</h2>
          <p className='bold'>{user.roleDescription} - <a className='blue' href={`mailto:${user.email}`}>{user.email}</a></p>
        </> }
        <h3>{ reference } gave the following answers</h3>
        { responseAnswers.map((responseAnswer) => {
          return <Answer
            reference={reference}
            questionId={responseAnswer.questionId}
            answerId={responseAnswer.answerId}
            attributes={responseAnswer}
          />
        })}
        { responseAnswers.length === 0 &&
          <p className='bold'>There were no questions to answer for this action.</p>
        }
      </Loading>
    </div>
  );
};
