import Loading from "Organiser/components/Loading"

export default function LiveTrackedActions() {
  const act = useAct()
  const { liveTrackedActions, liveTrackedActionsLoaded } = useSelector(s => s.dashboard)
  const history = useHistory()

  useEffect(() => {
    loadLiveTrackedActions()
  }, [])

  function loadLiveTrackedActions() {
    act.dashboard.upsert({ liveTrackedActionsLoaded: false })
    act.dashboard.loadLiveTrackedActions().then(() => {
      act.dashboard.upsert({ liveTrackedActionsLoaded: true })
    })
  }

  function redirect(checklistId, actionId) {
    history.push(
      `/checklists/${checklistId}/actions/${actionId}/status`
    )
  }

  return <div className='row margin-top double'>
    <div className='full'>
      <div className="box">
        <h3 className="no-margin-top">Actions you're tracking</h3>
        <Loading loaded={liveTrackedActionsLoaded}>
          { (liveTrackedActions || []).map(c => <div>
            <a class="blue-link" onClick={() => redirect(c.checklistId, c.actionId)}>{ c.name }</a>
          </div>) }
          { liveTrackedActions && liveTrackedActions.length === 0 && <p>None at the moment.</p> }
        </Loading>
      </div>
    </div>
  </div>
}