import Question from './Question'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

export default ({ checklistId, actionId }) => {
    const act = useAct()
    const questionIds = useSelector(s => _.orderBy(_.values(s.questions.entities).filter(q => q.actionId === actionId), 'questionOrder').map(q => q.id))
    const { adding } = useSelector(s => s.scriptEditor)
    const softLoad = useSelector(s => _.values(s.questions.entities).length > 25)

    useEffect(() => {
        if (actionId) {
            act.scriptEditor.clear()
            act.scriptEditor.load(checklistId, actionId)
        }
    }, [actionId])

    return <div className='script-editor'>
        <DndProvider backend={HTML5Backend}>
            <div className='questions'>
                {questionIds.map(id => <Question actionId={actionId} softLoad={softLoad} id={id} key={id} />)}
            </div>

            <a className='button secondary margin-top' onClick={() => act.scriptEditor.setAdding(!adding)}>Add question</a>

            {adding &&
                <div className='popup popup-list narrow'>
                    <div className='group'>
                        <div className='filter' onClick={() => act.scriptEditor.questions.add({ inputType: 'multiple_choice', actionId })}>
                            Multiple choice question
                        </div>
                        <div className='filter' onClick={() => act.scriptEditor.questions.add({ inputType: 'multi_select', actionId })}>
                            Multi-select question
                        </div>
                        <div className='filter' onClick={() => act.scriptEditor.questions.add({ inputType: 'rating', actionId })}>
                            Rating question
                        </div>
                        <div className='filter' onClick={() => act.scriptEditor.questions.add({ inputType: 'text', actionId })}>
                            Text question
                        </div>
                        <div className='filter' onClick={() => act.scriptEditor.questions.add({ inputType: 'number', actionId })}>
                            Number question
                        </div>
                        <div className='filter' onClick={() => act.scriptEditor.questions.add({ inputType: 'instruction', actionId })}>
                            Instruction question
                        </div>
                        <div className='filter' onClick={() => act.scriptEditor.questions.add({ inputType: 'date', actionId })}>
                            Date question
                        </div>
                        <div className='filter' onClick={() => act.scriptEditor.questions.add({ inputType: 'file', actionId })}>
                            File upload question
                        </div>
                        <div className='filter' onClick={() => act.scriptEditor.questions.add({ inputType: 'download', actionId })}>
                            File download question
                        </div>
                        <div className='filter' onClick={() => act.scriptEditor.questions.add({ inputType: 'address', actionId })}>
                            Address question
                        </div>
                    </div>
                </div>
            }
        </DndProvider>
    </div>
}
