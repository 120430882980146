import { Link } from "react-router-dom"

export default function RepDashboardList() {
  const act = useAct()
  const { dashboards } = useSelector(s => s.local)

  useEffect(() => {
    act.dashboards.load()
  }, [])

  return (
    <div>
      <h2>Rep Dashboards</h2>

      <Link to={`/rep_dashboards/new`} className="button primary">Create a new dashboard</Link>

      { (dashboards || []).map(dashboard => <div>
        <h3>{dashboard.name}</h3>
        { dashboard.actions.length > 0 && <p>Actions: {dashboard.actions.map(a => `${a.name} (${a.checklistName})`).join(', ')}</p> }
        { dashboard.actions.length === 0 && <p>This dashboard isn't on any live actions.</p> }
        <Link to={`/rep_dashboards/${dashboard.id}`} className="button smallest green">Edit</Link>
      </div>) }
    </div>
  );
};
