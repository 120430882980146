import { createSlice } from "@reduxjs/toolkit";

const actionEditorSlice = createSlice({
  name: "actionEditor",
  initialState: {},
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
});

function actions(dis, store, restClient) {
  function persist(checklistId, actionId, attributes) {
    const url = actionId === 'new' ? `/api/checklists/${checklistId}/actions`
      : `/api/checklists/${checklistId}/actions/${actionId}`
    // actionParams so we don't clash with 'action', reserved rack keyword
    return restClient.post(url, { actionParams: attributes })
      .then(response => {
        dis({
          type: "actionEditor/upsert",
          payload: { action: response.data },
        });
        return response;
      });
  }

  function remove(checklistId, actionId, redirectActionId) {
    const suffix = redirectActionId ? `?redirect_action_id=${redirectActionId}` : ''
    return restClient.delete(`/api/checklists/${checklistId}/actions/${actionId}${suffix}`)
      .then(response => {
        dis({
          type: "actionEditor/upsert",
          payload: { action: {} },
        });
        return response;
      });
  }

  function upsert(data) {
    dis({ type: `actionEditor/upsert`, payload: data })
  }

  return { persist, remove, upsert };
}

const reducer = actionEditorSlice.reducer;
export { actions, reducer };
