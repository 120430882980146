import { createSlice } from "@reduxjs/toolkit"

const dashboardsSlice = createSlice({
  name: "dashboards",
  initialState: {
    dashboards: []
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

function actions(dis, store, restClient) {
  function load() {
    return restClient.get(`/api/dashboards`)
    .then(({ data }) => {
      dis({ type: 'local/upsert', payload: { dashboards: data }})
    })
  }

  function loadDashboard(id) {
    return restClient.get(`/api/dashboards/${id}`)
    .then(({ data }) => {
      dis({ type: 'local/upsert', payload: { dashboard: data }})
    })
  }

  function create(attributes) {
    return restClient.post(`/api/dashboards/create`, { dashboard: attributes })
    .then(({ data }) => {
      dis({ type: 'local/upsert', payload: { dashboard: data }})
    })
  }

  function update(id, attributes) {
    return restClient.post(`/api/dashboards/${id}`, { dashboard: attributes })
    .then(({ data }) => {
      dis({ type: 'local/upsert', payload: { dashboard: data }})
    })
  }

  return {
    load, loadDashboard, update, create
  }
}

const reducer = dashboardsSlice.reducer
export { actions, reducer }