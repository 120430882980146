const ActionColumns = [
  {
    data: 'name',
    className: 'bold',
    render: function (data, type, row, meta) {
      return `<a class='legacy' href='/action_takers/${row['DT_RowId']}'>${row['name']}</a>`
    },
  },
  {
    data: 'checklist_name',
    render: function (data, type, row, meta) {
      return `<span class="capitalize">${row['checklist_name']}</span>`
    },
  },
  {
    data: 'completed_at',
    render: function (data, type, row, meta) {
      return `<span class="capitalize">${row['completed_at']}</span>`
    },
  }
]

const ActionHeaders = [
  'Name',
  'Checklist',
  'Completed at'
]

const ActionFilters = [
]

const ActionSorters = [
]

const ActionOrder = [[ 0, "asc" ]]

export { ActionColumns, ActionHeaders, ActionFilters, ActionSorters, ActionOrder }
