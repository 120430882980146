import { createFilter } from "react-select"
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import pluralize from 'pluralize'

export default ({ components, redirectUrl, value, onChange, entity, queryParameters = {}, multiple = false, disabled = false, defaultInputValue = "Start typing", loadStaticList = false }) => {
  const [defaultOptions, setDefaultOptions] = useState(null)
  const client = applyCaseMiddleware(axios.create())
  const labels = useRef({})

  function applyDefaultOptions(options) {
    options.forEach(({ value, label }) => {
      labels.current[value] = label
    })
    setDefaultOptions(options)
  }

  useEffect(() => {
    if (value) {
      if (multiple && value.length > 0) {
        client.get(`/api/${_.snakeCase(pluralize(entity))}`, { params: { ids: value } })
          .then(({ data }) => applyDefaultOptions(data))
      } else if (!multiple) {
        client.get(`/api/${_.snakeCase(pluralize(entity))}/${value}`)
          .then(({ data: { id, name } }) => applyDefaultOptions([{ value: id, label: name }]))
      }
    } else {
      applyDefaultOptions([])
    }
  }, [])

  useEffect(() => {
    if (disabled) return

    if (loadStaticList) {
      client.get(`/api/${_.snakeCase(pluralize(entity))}`, { params: queryParameters })
        .then(({ data }) => applyDefaultOptions(data))
    }
  }, [JSON.stringify(queryParameters)])

  const dLoadOptions = useMemo(() => {
    function loadOptions(text, callback) {
      client.get(`/api/${_.snakeCase(pluralize(entity))}/search`, { params: _.merge(queryParameters, { query: text }) })
        .then(({ data }) => callback(data))
    }

    return _.debounce(loadOptions, 500)
  }, [queryParameters])

  if (!defaultOptions) { return <div>Loading...</div> }

  function handleChange(objs) {
    if (multiple) {
      const values = objs ? objs.map(v => v.value) : []
      onChange(values)
      (objs || []).forEach(({ value, label }) => {
        labels.current[value] = label
      })
    } else {
      onChange(objs.value)
      labels.current[objs.value] = objs.label
    }
  }

  function selectValue() {
    if (multiple) {
      if (!value) return null
      return value.map(v => ({ value: v, label: labels.current[v] }))
    } else {
      if (!value) return null
      return { value, label: labels.current[value] }
    }
  }

  return <div className='root'>
    <div style={{width: '300px'}}>
      <AsyncSelect
        cacheOptions
        isMulti={multiple}
        loadOptions={dLoadOptions}
        defaultOptions={defaultOptions}
        defaultValue={defaultOptions}
        value={selectValue()}
        components={components}
        width='300px'
        isDisabled={disabled}
        redirectUrl={redirectUrl}
        noOptionsMessage={() => "Start typing"}
        onChange={ handleChange }
        filterOption={createFilter({ ignoreAccents: false })} />
    </div>
  </div>
}
