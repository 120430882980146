import useCurrentUser from "Organiser/useCurrentUser"
import { Link } from "react-router-dom"
import BranchChecklists from "./Partials/BranchChecklists";
import WaveImage from "images/wave.png"
import MyChecklists from 'Organiser/views/Dashboards/Partials/MyChecklists'

export default function DashboardBranch() {
  const { user } = useCurrentUser();
  const permission = useEager('permissions', user?.defaultPermissionId)
  const organisation = useEager('organisations', permission?.organisationId)

  if (!user || !permission || !organisation) {
    return null;
  }

  return (
    <div>
      <h2>
        <img className='icon' src={WaveImage} />  <strong>{user.name}</strong>, welcome back to{" "}
        <strong>{organisation.name}</strong>
      </h2>
      <div className="flex space-x-2">
        <div className="box p-4 w-1/2">
          <h3 className="no-margin-top">
            Welcome to NEU Activate.
          </h3>
          <p>Welcome to NEU Activate, our digital ballot platform.</p>
          <p>You will find any ballot actions you need to complete below.</p>
          <p>For questions relating to the re-ballot please read our FAQs and other info here: <a href="https://neu.org.uk/payup" target="_blank">https://neu.org.uk/payup</a></p>
        </div>
      </div>

      <MyChecklists />
    </div>
  );
};
