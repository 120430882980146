function actions(dis, store, restClient) {
  function loadAll() {
    return restClient.get(`/api/admin_statistics`)
      .then(({ data }) => {
        const { adminStatistics } = data

        adminStatistics.forEach(e => dis({ type: "adminStatistics/upsert", payload: e }))
      })
  }

  return { loadAll }
}

export { actions }