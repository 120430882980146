const entityNames = [
  'users',
  'permissions',
  'organisationChecklists',
  'checklists',
  'actions',
  'questions',
  'responseAnswers',
  'actionResponses',
  'answers',
  'organisations',
  'checklistUsers',
  'adminStatistics',
  'actionStatuses'
]

export default entityNames