import { createSlice } from "@reduxjs/toolkit"
import createHttpClient from "Organiser/createHttpClient"

function toggle(state, id, setting, children) {
  state[id] = setting;
  if (children[id]) {
    children[id].forEach(function(childId, index_) {
      state = toggle(state, childId, setting, children)
    });
  }
  return state
}

const initialState = { nationalOrganisationId: null, expanded: {}, selected: {}, organisations: {}, children: {} }

const organisationSlice = createSlice({
  name: "organisation",
  initialState,
  reducers: {
    reset: (s,a) => initialState,
    expand: (s, a) => {
      s.expanded[a.payload.organisationId] = a.payload.expand;
      return s;
    },
    fillChildren: (s, a) => {
      // store parent
      s.organisations[a.payload.organisation.id] = a.payload.organisation;

      // store children
      a.payload.children.forEach((child, index) => {
        s.organisations[child.id] = child;
      })

      // store link
      const childIds = a.payload.children.map((child) => child.id)
      s.children[a.payload.organisationId] = childIds;
      return s;
    },
    setNationalOrganisationId: (s, a) => {
      s.nationalOrganisationId = a.payload.organisationId
      return s;
    },
    setSelections: (s, a) => {
      s.selected = a.payload.selections;
      return s;
    },
    toggle: (s, a) => {
      const old = s.selected[a.payload.organisationId];
      const setting = a.payload.maintain ? old : (old ? false : true);
      s.selected = toggle(s.selected, a.payload.organisationId, setting, s.children)
      return s;
    }
  },
});

function actions(dis, store, restClient) {
  function expand(organisationId, expand) {
    dis({
      type: "organisation/expand",
      payload: { organisationId, expand },
    });
  }

  function loadChildren(organisationId, actionId) {
    const url = `/api/organisations/${organisationId}/children${actionId ? `?include_action=${actionId}` : ''}`
    return createHttpClient()
      .get(url)
      .then(response => {
        dis({
          type: "organisation/fillChildren",
          payload: { organisationId: response.data.organisation.id, children: response.data.children, organisation: response.data.organisation },
        });
        return response;
      });
  }

  function loadChecklistSelections(checklistId) {
    return createHttpClient()
      .get(`/api/checklists/${checklistId}/organisations`)
      .then(response => {
        var selections = {};
        response.data.organisationIds.forEach(id => {
          selections[id] = true
        });
        dis({
          type: "organisation/setSelections",
          payload: { selections },
        });
        return response;
      });
  }

  function setNationalOrganisationId(organisationId) {
    dis({
      type: "organisation/setNationalOrganisationId",
      payload: { organisationId }
    })
  }

  function toggle(organisationId, maintain = false) {
    dis({
      type: "organisation/toggle",
      payload: { organisationId, maintain }
    })
  }

  function reset() {
    console.log('reset')
    dis({
      type: "organisation/reset"
    })
  }

  return { expand, loadChecklistSelections, loadChildren, setNationalOrganisationId, toggle, reset };
}

const reducer = organisationSlice.reducer;
export { actions, reducer };
