function actions(dis, store, restClient) {
  function loadChecklists(organisationId) {
    return restClient.get(`/api/organisations/${organisationId}/checklists`)
      .then(({ data }) => {
        const { organisationChecklists, checklists } = data

        organisationChecklists.forEach(e => dis({ type: "organisationChecklists/upsert", payload: e }))
        checklists.forEach(e => dis({ type: "checklists/upsert", payload: e }))
      })
  }

  return { loadChecklists }
}

export { actions }