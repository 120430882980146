const ActionTakerColumns = [
  {
    data: 'name',
    className: 'bold',
    render: function (data, type, row, meta) {
      return `<a class='legacy' href='/action_takers/${row['DT_RowId']}'>${row['name']}</a>`
    },
  },
  {
    data: 'organisation_name',
    render: function (data, type, row, meta) {
      return `<span class="capitalize">${row['organisation_name']}</span>`
    },
  }
]

const ActionTakerHeaders = [
  'Name',
  'School'
]

const ActionTakerFilters = [
]

const ActionTakerSorters = [
]

const ActionTakerOrder = [[ 0, "asc" ]]

export { ActionTakerColumns, ActionTakerHeaders, ActionTakerFilters, ActionTakerSorters, ActionTakerOrder }
