import Loading from 'Organiser/components/Loading'
import { useEffect } from 'react'
import './Status.scoped.scss'

const LABELS = {
  'start': 'Not posted',
  'complete': 'Ballot posted',
  'unstarted': 'Not posted'
}

export default function ActionStatus() {
  const act = useAct()
  const { actionId, checklistId } = useParams()
  const {
    statuses, status, dashboardName, dashboardColumns, showStatus, statusLabel, helpText, enableConversations
  } = useSelector(s => s.local)
  const columnWidth = `${100 / ((dashboardColumns?.length || 0) + 2 + (showStatus ? 1 : 0) + (enableConversations ? 1 : 0))}%`
  const [filter, setFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState({})
  const [help, setHelp] = useState(false)
  const action = useEager('actions', actionId)
  const [showWarning, setShowWarning] = useState(false)
  const [url, setUrl] = useState('')
  const [scope, setScope] = useState('all')
  const filtered = _.orderBy(_.map(statuses || {}, function(v, k) {
    return { ...v, userId: k, votingOrder: v.status === 'complete' ? 'z' : 'a' }
  }).filter(a => {
    if (scope === 'not_voted' && a.status === 'complete') {
      return false
    }

    if (scope === 'not_spoken_to' && a.conversation === true) {
      return false
    }

    var matches = filter.length > 0 ? 
      ((a.name || '').toLowerCase().includes(filter.toLowerCase()) ||
      (a.email || '').toLowerCase().includes(filter.toLowerCase())) : true
    
    for (var index in columnFilters) {
      console.log(index, columnFilters[index], a.dashboardColumns[index])
      if (columnFilters[index] === true && !a.dashboardColumns[index]) {
        matches = false
      } else if (columnFilters[index] === false && a.dashboardColumns[index]) {
        matches = false
      }
    }
    return matches
  }), ['votingOrder', 'name'])

  useEffect(() => {
    act.checklists.load(checklistId)
    update(true)

    const interval = setInterval(() => {
      update(false)
    }, 30000)
    return () => clearInterval(interval)
  }, [checklistId])

  function update(full = false) {
    act.actionStatuses.load(actionId, full)
  }

  function setColumnFilter(index, status) {
    if (status === 'all') {
      var newFilters = { ...columnFilters }
      delete newFilters[index]
      setColumnFilters(newFilters);
    } else {
      setColumnFilters(prevFilters => ({ ...prevFilters, [index]: status === 'on' }));
    }
  }

  function toggleConversation(userId) {
    return act.actionResponses.toggleConversation(actionId, userId).then(() => {
      update()
    })
  }

  function toggleDashboardColumn(userId, field) {
    return act.actionResponses.toggleDashboardColumn(actionId, userId, field).then(() => {
      update()
    })
  }

  function download() {
    setShowWarning(true)
    return act.actionStatuses.downloadPdf(actionId).then((data) => {
      setShowWarning(false)
      setUrl(data.data.url)
      console.log(data)
    })
  }

  return (
    <div>
      <Loading loaded={typeof action.name !== 'undefined'}>
        <h3 className='dark-grey'>{ dashboardName }</h3>

        { status === 'generating' &&
          <h4>We're assembling live information now. Please check back shortly.</h4>
        }
        { status === 'on' && <>
          { !help && <p>For an explanation of this page, and help using it, please <a onClick={() => setHelp(true)} className='blue-link'>click here</a></p> }
          { help && <div className='margin-bottom' dangerouslySetInnerHTML={{__html: helpText}} /> }
          
          { showWarning && <p>Your PDF is generating now. Please wait.</p> }
          { url && <a className='blue-link' href={url} download>Download PDF</a> }
          { !url && <ServerWaitButton onClick={download} className='button primary'>Export as PDF</ServerWaitButton> }
          <br/>

          <input className='margin-bottom margin-top' placeholder='Search' type='text' onChange={(e) => setFilter(e.target.value)} />

          <p className='filter'>Show:
            <br/>
            <a className={`blue-link ${scope === 'all' ? 'bold' : ''}`} onClick={() => setScope('all')}>Everyone</a>
            <span class='ellipsis wider'></span>
            <a className={`blue-link ${scope === 'not_spoken_to' ? 'bold' : ''}`} onClick={() => setScope('not_spoken_to')}>Members not spoken to</a>
            { dashboardColumns?.map((c, i) => {
                return dashboardColumns[i].allowFilter ? <>
                  <span class='ellipsis wider'></span>
                  <span>{ dashboardColumns[i].name }</span>
                  &nbsp;
                  <a className={`blue-link ${columnFilters[i] === true ? 'bold' : ''}`} onClick={() => setColumnFilter(i, 'on')}>
                    Yes
                  </a>&nbsp; / &nbsp;
                  <a className={`blue-link ${columnFilters[i] === false ? 'bold' : ''}`} onClick={() => setColumnFilter(i, 'off')}>
                    No
                  </a>&nbsp; / &nbsp;
                  <a className={`blue-link ${typeof columnFilters[i] === 'undefined' ? 'bold' : ''}`} onClick={() => setColumnFilter(i, 'all')}>
                    All
                  </a>
                </> : null
            }) }
          </p>
          
          <table>
            <thead>
              <tr>
                <th width={columnWidth}>Name</th>
                <th width={columnWidth}>Membership #</th>
                { showStatus && <th width={columnWidth}>{ statusLabel }</th> }
                { enableConversations && <th width={columnWidth}>Conversation</th> }
                { dashboardColumns.map(c => {
                  return <th width={columnWidth}>{ c.name }</th>
                }) }
              </tr>
            </thead>
            <tbody>
              { filtered.map((status) => {
                return <tr className='taker' key={ status.name }>
                  <td className='col name'>
                    { status.recentlyJoined ? <span className='bold'>(NEW) </span> : '' }
                    { status.name }
                    { status.volunteerJoin ? ' (Volunteer)' : '' }
                  </td>
                  <td className='col email'>{ status.externalId }</td>
                  { showStatus && <td className={`col ${status.status === 'complete' ? 'complete' : 'unstarted'}`}>
                    <div className='inner'>
                      <p>{ status.status === 'complete' ? 'Done' : 'Not started' }</p>
                    </div>
                  </td> }
                  { enableConversations && <td className='col conversation'>
                    <div className='inner'>
                      <p>
                        { status.conversation ? 'Spoken to' : 'Not spoken to' }
                        { status.conversationHaver && <span><br/>(by { status.conversationHaver })</span> }
                      </p>
                      <ServerWaitButton className='button smallest primary' loaderHeight={12} loaderColor={'black'} onClick={() => toggleConversation(status.userId)}>Toggle</ServerWaitButton>
                    </div>
                  </td> }
                  { status.dashboardColumns.map((c, i) => {
                    return <td className={`col ${dashboardColumns[i].colourCode ? (c ? 'complete' : 'unstarted') : ''}`}>
                      <div className='inner'>
                        { dashboardColumns[i].source === 'custom_field' && <p>{ c }</p> }
                        { dashboardColumns[i].source === 'audience' && <p>{ c ? 'Yes' : 'No' }</p> }
                        { dashboardColumns[i].source === 'from_question' && <p>{ c }</p> }
                        { dashboardColumns[i].allowToggle && <ServerWaitButton
                          className='button smallest primary'
                          loaderHeight={12}
                          loaderColor={'black'}
                          onClick={() => toggleDashboardColumn(status.userId, dashboardColumns[i].slug)}
                        >Toggle</ServerWaitButton> }
                      </div>
                    </td>
                  }) }
                </tr>
              })}
            </tbody>
          </table>
        </> }
      </Loading>
    </div>
  );
};
