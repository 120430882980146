import './Answer.scoped.scss'
import { useDrag } from 'react-dnd'
import { useDrop } from 'react-dnd'
import CloseSvg from "../../../../images/close.svg"
import AdvancedSettings  from './AdvancedSettings'

export default function Answer ({ id, showAdvanced }) {
  const act = useAct()
  const { answer, questionId, answerOrder } = useSelector(s => s.answers.entities[id])

  const [, drag, preview] = useDrag({
    item: { type: `${questionId}-answer`, id }
  });

  const [, drop] = useDrop({
    accept: `${questionId}-answer`,
    drop(item, monitor) {
      act.scriptEditor.answers.moveOrder(item.id, answerOrder)
    }
  });

  const ref = el => drag(drop(el))

  return <div className='answer' ref={ref}>
    <div className='dragger'><div className='handle'></div></div>
    <div className='content'>
      <div className="content-editor condensed">
        <input type='text' value={answer} onChange={(e) => act.scriptEditor.answers.upsert(id, { answer: e.target.value })} />
      </div>
      <button className='delete' onClick={() => act.scriptEditor.answers.remove(id)}><img src={CloseSvg} /></button>
      { showAdvanced && <AdvancedSettings answerId={id} /> }
    </div>
  </div>
  }
