import { useParams, Link } from "react-router-dom"
import useCurrentUser from "Organiser/useCurrentUser"
import Loader from "react-loader-spinner"

export default function Begin() {
  const act = useAct()
  const { checklistId } = useParams()
  const { user } = useCurrentUser()
  const userId = user.id

  useEffect(() => {
    act.checklistUsers.loadBy({ checklistId, userId })
  }, [checklistId, userId])

  const checklist = useEager("checklists", checklistId)
  const checklistUser = useSelector(s =>
    _.find(_.values(s.checklistUsers.entities), cu => parseInt(cu.checklistId) === parseInt(checklistId))
  )

  useEffect(() => {
    act.checklists.load(checklistId)
  }, [checklistId])
  const currentAction = useSelector(s => s.actions.entities[checklistUser?.currentActionId])

  const loaded = checklist && checklistUser && currentAction

  return (
    <div>
      {!loaded && (
        <Loader
          type="Bars"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      )}
      {loaded && (
        <>
          <h2>{checklist.name} - Getting Started</h2>
          <div className="flex space-x-2">
            <div className="w-1/2 box p-4">
              <div>{checklist.subject}</div>
              <div dangerouslySetInnerHTML={{ __html: checklist.body }} />
            </div>
            <div className="w-1/2 box p-4">
              <div>
                <Link
                  to={`/checklists/${checklist.id}/actions/${currentAction.id}/begin`}
                  className="button primary js-begin-checklist"
                >
                  Begin
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
