import pluralize from 'pluralize'

export default (dis, store, entity, initialData = {}, extraActions = {}) => {
  function add(data = {}) {
    const id = uuidv4()
    dis({ type: `${pluralize(entity)}/upsert`, payload: { id, ...initialData, ...data } })
  }

  function upsert(id, data) {
    dis({ type: `${pluralize(entity)}/upsert`, payload: { id, ...data } })
  }

  function setAll(data) {
    dis({ type: `${pluralize(entity)}/setAll`, payload: data })
  }

  function remove(id) {
    dis({ type: `${pluralize(entity)}/remove`, payload: id })
  }

  const extraActionsResolved = _.mapValues(extraActions, v => v(dis, store, entity))

  return { add, upsert, setAll, remove,...extraActionsResolved }
}