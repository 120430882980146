import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"

export default () => {
  return (
    <div>
      <h2>Login</h2>
      <div className="">
        {window.ENV.FEATURE_NEU_LOGIN_ENABLED && (
          <div className="margin-bottom">
            <a href="/login_with_open_id" className="button primary">
              Login with NEU
            </a>
          </div>
        )}
        {!window.ENV.FEATURE_NEU_LOGIN_ENABLED && (
          <div className="margin-bottom">
            <a href="/users/sign_in" className="button primary">
              Login with a username & password
            </a>
          </div>
        )}
        <div className="margin-bottom">
          <Link to="/login/magic_link" className="button secondary">
            Email me a magic link to login
          </Link>
        </div>
      </div>
    </div>
  )
}
