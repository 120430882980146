function actions(dis, store, restClient) {
  function loadCurrentUser() {
    return restClient.get(`/api/users/me`)
      .then(({ data }) => {
        const { users, organisations, permissions, enableForums } = data

        if (!users) return

        users.forEach(user => dis({ type: "users/upsert", payload: user }))
        organisations.forEach(org => dis({ type: "organisations/upsert", payload: org }))
        permissions.forEach(p => dis({ type: "permissions/upsert", payload: p }))
        dis({ type: 'local/upsert', payload: { enableForums } })
      })
  }

  function load(id) {
    return restClient.get(`/api/users/${id}`)
  }

  return { loadCurrentUser, load };
}

export { actions }