import './PageContainer.scoped.scss'
import useCurrentUser from "Organiser/useCurrentUser"
import Nav from "Organiser/Nav"
import Sidebar from './Sidebar'
import Snackbar from 'Organiser/components/Snackbar'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

Sentry.init({
  dsn: window.ENV.SENTRY_PUBLIC_DSN,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: window.ENV.SENTRY_SAMPLE_RATE || 1.0,
  normalizeDepth: 10 // How deep to go in Redux state tree
});

export default function PageContainer({ children }) {
  const { user, isLoadingUser } = useCurrentUser()
  const history = useHistory()

  if (!user && !isLoadingUser && !window.location.pathname.startsWith("/login")) {
    history.push("/login")
  }

  useEffect(() => {
    $(document).on('click', 'a.legacy', function(event) {
      event.preventDefault()
      history.push($(this).attr('href'))
    })
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred"} showDialog={true}>
      <div className='page'>
        <Sidebar />
        <Snackbar />

        <div className='content'>
          <Nav />

          <div className='view'>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </Sentry.ErrorBoundary>
  );
}
