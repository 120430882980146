import useCurrentUser from "Organiser/useCurrentUser";
import { Link } from "react-router-dom";
import Loading from 'Organiser/components/Loading'
import RocketImage from "../../../../images/rocket.png"
import WaveImage from "../../../../images/wave.png"

const UserCount = () => {
  const act = useAct()
  useEffect(() => {
    act.adminStatistics.loadAll()
  }, [])

  const adminStatistics = useSelector(
    s => Object.values(s.adminStatistics.entities)[0]
  )
  if (!adminStatistics) {
    return null;
  }

  return (
    <h3 className='no-margin-top'>
      <span className="font-bold">{adminStatistics.userCount} </span>
      <span>members registered</span>
    </h3>
  )
}

export default function DashboardAdmin() {
  const act = useAct()
  const { user } = useCurrentUser()
  const { checklists, loaded, mandatedBy } = useSelector(s => s.dashboard)

  useEffect(() => {
    load()
  }, [])

  function load() {
    act.dashboard.upsert({ loaded: false })
    act.dashboard.loadChecklists(mandatedBy).then(() => {
      act.dashboard.upsert({ loaded: true })
    })
  }

  return (
    <div>
      <h2>
        <img src={WaveImage} className='icon' /> Hi, {user.name}
      </h2>

      <div className="flex space-x-2">
        <div className="box p-4 w-1/2">
          <UserCount />
          <div>
            <p>Welcome to NEU Activate.</p>

            <p>Here you can set up review data in the dashboard, create national level actions, review checklists created by regions and branches and more.</p>

            <p>Questions or feedback? Email <a href="mailto:activate@neu.org.uk">activate@neu.org.uk</a>.</p>
          </div>
        </div>

        <div className="box p-4 w-1/2">
          <h3 className='no-margin-top'><img src={RocketImage} className='icon' /> Live Journeys</h3>
          <div className="mb-2">
            <Loading loaded={loaded}>
              {checklists.map(checklist => (
                <div key={checklist.id} className="flex inset p-1 items-center">
                  <div className="flex-grow">{checklist.name}</div>
                  <div className="">
                    <Link to={`/checklists/${checklist.id}`} type="button" className="button smallest green">
                      Go
                    </Link>
                  </div>
                </div>
              ))}
              {checklists.length === 0 && <p>There's no live national journeys currently. There's probably some sent by regions and branches, which you can view by clicking 'See all' below.</p>}
            </Loading>
          </div>
          <div className="actions margin-top">
            <Link to="/checklists/new" className="button primary">Add new</Link>
            <Link to="/checklists" className="button naked link">See all</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
