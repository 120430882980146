const ChecklistColumns = [
  {
    data: 'name',
    className: 'bold',
    render: function (data, type, row, meta) {
      return `<a class='legacy' href='/checklists/${row['DT_RowId']}'>${row['name']}</a>`
    },
  },
  {
    data: 'state',
    render: function (data, type, row, meta) {
      return `<span class="capitalize">${row['state']}</span>`
    },
  },
  {
    data: 'mandated_by',
    render: function (data, type, row, meta) {
      return `<span class="capitalize">${row['mandated_by']}</span>`
    },
  }
]

const ChecklistHeaders = [
  'Name',
  'Status',
  'Mandated by'
]

const ChecklistFilters = [
  { name: 'Status', column: 1, options: [
    { value: '', label: 'All' },
    { value: 'draft', label: 'Draft' },
    { value: 'live', label: 'Live' },
    { value: 'closed', label: 'Closed' }
  ] },
  { name: 'Mandated by', column: 2, options: [
    { value: '', label: 'All' },
    { value: 'national', label: 'National' },
    { value: 'regional', label: 'Region' },
    { value: 'branch', label: 'Branch' }
  ] },
]

const ChecklistSorters = [
]

const ChecklistOrder = [[ 0, "asc" ]]

export { ChecklistColumns, ChecklistHeaders, ChecklistFilters, ChecklistSorters, ChecklistOrder }
