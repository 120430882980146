import Loading from "Organiser/components/Loading"
import SmileImage from "images/smile.png"

export default function BallotActions() {
  const act = useAct()
  const history = useHistory()
  const { unstartedChecklists, loaded } = useSelector(s => s.dashboard)
  const visibleActions = _.filter(unstartedChecklists, { finished: false })
  const receivedAction = visibleActions.find(a => window.ENV.RECEIVED_ACTIONS.includes(a.id))
  const votedAction = visibleActions.find(a => window.ENV.VOTED_ACTIONS.includes(a.id))
  const requestAction = visibleActions.find(a => window.ENV.REQUEST_ACTIONS.includes(a.id))
  const noActions = !receivedAction && !votedAction && !requestAction

  useEffect(() => {
    loadActions()
  }, [])

  function loadActions() {
    act.dashboard.upsert({ loaded: false })
    act.dashboard.loadMyChecklists().then(() => {
        act.dashboard.upsert({ loaded: true })
    })
  }

  function begin(checklist) {
    if (checklist.started) {
      redirect(checklist)
    } else {
      act.checklist.begin(checklist.id).then(() => {
        redirect(checklist)
      })
    }
  }

  function redirect(checklist) {
    history.push(
        `/checklists/${checklist.id}/actions/${checklist.currentAction.id}/begin`
    )
  }

  return <div className="box margin-top">
        <Loading loaded={loaded}>
            { noActions && <>
              <h3>Ballot actions</h3>
              <p className='no-margin-top'><img src={SmileImage} className='icon' /> You've not got any new actions to do right now, nice one!</p>
            </> }
            { receivedAction && <>
                <h3 className='no-margin-top'>Received your ballot paper?</h3>
                <p>Let us know that you've got it:</p>
                <a className='button primary' onClick={() => begin(receivedAction)}>I've received my ballot paper</a>
            </> }
            { votedAction && <>
                <h3>Posted your ballot paper?</h3>
                <p>Let us know here and stop receiving reminders:</p>
                <a className='button secondary' onClick={() => begin(votedAction)}>I've posted my ballot</a>
            </> }
            { requestAction && <>
                <h3>Not received your ballot paper?</h3>
                <p>Let us know, and we can send you a new one.</p>
                <a className='button secondary' onClick={() => begin(requestAction)}>Request a new ballot paper</a>
            </> }
        </Loading>
    </div>
}