import { useParams, useHistory } from "react-router-dom"
import Loading from "Organiser/components/Loading"
import Question from "Organiser/components/Survey/Question"

export default function ActionSurvey() {
  const act = useAct()
  const history = useHistory()
  const { actionId, checklistId } = useParams()
  const action = useEager('actions', actionId)
  const actionResponseId = useSelector(s => s.action.actionResponseId)
  const actionResponse = useEager('actionResponses', actionResponseId)
  const currentQuestion = useSelector(s => s.questions.entities[actionResponse?.currentQuestionId])
  const loaded = action.id && actionResponse.id && currentQuestion
  const fakeResponseMode = useSelector(s => s.local.fakeResponseMode)

  useEffect(() => {
    if (actionResponse.id && actionResponse.completedAt) {
      history.push(`/checklists/${checklistId}/actions/${action.id}/complete/${actionResponseId}`);
    }
  }, [actionResponse])

  useEffect(() => {
    act.actions.load(actionId, true),
    act.actionResponses.load(actionResponseId, actionId)
  }, [actionId, actionResponseId])

  return (
    <div>
      { fakeResponseMode && <div className='alert'>Warning: You are testing this survey and responses will not be saved.</div> }
      <Loading loaded={loaded}>
        <Question
          key={currentQuestion?.id}
          question={currentQuestion}
          action={action}
          checklistId={checklistId}
        />
      </Loading>
    </div>
  );
};
