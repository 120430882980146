import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import Loading from 'Organiser/components/Loading'
import useCurrentUser from "Organiser/useCurrentUser"
import ChecklistHeader from 'Organiser/views/Checklists/Partials/ChecklistHeader'

function canEditChecklist(mandate, accessLevel) {
  if (accessLevel === 'admin') {
    return true
  } else if (mandate === 'regional' && accessLevel === 'regional') {
    return true
  } else if (mandate === 'branch' && accessLevel === 'branch_secretary') {
    return true
  }
  return false
}

function Action({ checklist, action, lastOrder, canAdministrate, showReorder }) {
  const act = useAct()
  const history = useHistory()
  const { user } = useCurrentUser()
  const permission = useEager('permissions', user?.defaultPermissionId)
  const organisation = useEager('organisations', permission?.organisationId)
  const archived = action.archivedAt !== null

  function test() {
    act.action.startFake()
    history.push(`/checklists/${checklist.id}/actions/${action.id}/begin`)
  }

  function up() {
    act.actions.moveUp(checklist.id, action.id)
  }

  function down() {
    act.actions.moveDown(checklist.id, action.id)
  }

  return <div key={action.id}>
    <div className={`flex inset p-2 items-center ${archived ? 'archived' : ''}`}>
      <div className="left">
        <div className='number'>{action.actionOrder + 1}</div>

        <p className='name'>
          {action.name} { archived ? '(Hidden)' : '' }
          { action.liveTracking && <span className='smaller bold'><br/>Live tracking by reps enabled</span> }
        </p>

        { showReorder && <p className='full-width margin-top'>
          { action.actionOrder > 0 && <a className='blue-link margin-right' onClick={up}>Move up</a> }
          { action.actionOrder != lastOrder && <a className='blue-link' onClick={down}>Move down</a> }
        </p> }
      </div>

      <div className='stats'>
        <div className='number stat'>{action.startedCount} started</div>
        <div className='number stat'>{action.completedCount} completed</div>
      </div>

      <div className='buttons'>
        { (canAdministrate && !archived) && <>
          <Link to={`/checklists/${checklist.id}/actions/${action.id}/edit`} className="button smallest secondary">Edit</Link>
          { action.liveTracking && <a className="button smallest green" onClick={() => act.actions.exportTrackingLinks(action.id)}>Export live tracking links</a> }
        </> }
        <Link to={`/checklists/${checklist.id}/actions/${action.id}/results`} className="button smallest green">Results</Link>
        <button className="button smallest primary" onClick={test}>Preview</button>
      </div>
    </div>
  </div>
}

export default function ChecklistShow() {
  const act = useAct()
  const { checklistId } = useParams()
  const { user } = useCurrentUser()
  const [showReorder, setShowReorder] = useState(false)
  const checklist = useEager('checklists', checklistId)
  const actions = useSelector(s =>
    _.orderBy(Object.values(s.actions.entities).filter(
      a => a.checklistId === parseInt(checklistId)
    ), 'actionOrder', 'asc'))
  const canAdministrate = canEditChecklist(checklist?.mandatedBy, user?.accessLevel)
  const canAddActions = () => {
    const canCreateChecklists = ["admin", "national"].includes(user?.accessLevel)
    if (canCreateChecklists) {
      return canAdministrate && checklist?.state !== 'closed'
    } else {
      return actions.length === 0 && checklist?.state !== 'closed'
    }
  }
  const permission = useSelector(s => s.permissions.entities[user?.defaultPermissionId])

  useEffect(() => {
    return act.checklists.poll(checklistId, permission?.organisationId)
  }, [checklistId])

  function toggleReorder() {
    setShowReorder(!showReorder)
  }

  return (
    <Loading loaded={typeof checklist.name !== 'undefined'}>
      <ChecklistHeader canAdministrate={canAdministrate} checklistId={checklistId} />

      <div className="box p-4">
        <h3 className='no-margin-top'>Steps</h3>
        { actions.map(action => (
          <Action
            checklist={checklist}
            action={action}
            showReorder={showReorder}
            canAdministrate={canAdministrate}
            lastOrder={actions.at(-1).actionOrder}
            key={action.id} />
          ))
        }
        { actions.length === 0 && <p>Set up your first step.</p> }
        { canAddActions() && <Link to={`/checklists/${checklist.id}/actions/new`} className="button secondary smaller margin-top double">Add a new step</Link> }
        { canAdministrate && <p className='no-margin-bottom'>
          <a className='blue-link' onClick={toggleReorder}>{ showReorder ? 'Done re-ordering' : 'Re-order actions' }</a>
        </p> }
      </div>
    </Loading>
  );
}
