import {
  createSlice,
  createReducer,
} from "@reduxjs/toolkit";

const { reducer } = createSlice({
  name: "email",
  initialState: {
    subject: '',
    body: '',
    dirty: false,
    isKick: false,
    unsentCount: 0
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  }
})

function actions(dis, store, restClient) {
  function getOrganisationId() {
    const state = store.getState()
    const permissionId = state.users.entities[String(window.ENV.CURRENT_USER_ID)].defaultPermissionId

    return state.permissions.entities[String(permissionId)].organisationId
  }

  function upsert(payload) {
    dis({ type: 'email/upsert', payload: { dirty: true, ...payload } })
  }

  function clear() {
    dis({ type: 'email/set', payload: { subject: '', body: '' } })
  }

  function load(checklistId) {
    return restClient.get(`/api/checklists/${checklistId}/email_counts`, {
      params: {
        organisationId: getOrganisationId()
      }
    })
    .then(({ data: { subject, body, unsentCount, unstartedCount } }) => {
      upsert({ subject, body, unsentCount, unstartedCount, dirty: false })
    })
  }

  function send({ checklistId, subject, body, isKick }) {
    const parentOrganisationId = getOrganisationId()

    return restClient.post(`/api/checklists/${checklistId}/send_blast`, { parentOrganisationId, subject, body, isKick })
    .then(() => upsert({ sendingModal: true }))
  }

  return { upsert, load, send }
}

export { actions, reducer }
