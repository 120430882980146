import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Link,
  Redirect,
} from "react-router-dom";

import PageContainer from "Organiser/components/PageContainer";
import Login from "Organiser/views/Login";
import LoginWithMagicLink from "Organiser/views/LoginWithMagicLink"

// dashboards
import DashboardActivist from "Organiser/views/Dashboards/Activist";
import DashboardBranch from "Organiser/views/Dashboards/Branch";
import DashboardAdmin from "Organiser/views/Dashboards/Admin";

// checklists
import ChecklistDashboard from "Organiser/views/ChecklistDashboard";
import ChecklistList from "Organiser/views/Checklists/List"
import ChecklistNew from "Organiser/views/Checklists/New";
import ChecklistShow from "Organiser/views/Checklists/Show";
import ChecklistBegin from "Organiser/views/Checklists/Begin";
import ChecklistEmail from "Organiser/views/Checklists/Email"

// actions
import ActionBuild from "Organiser/views/Actions/Build";
import ActionResults from "Organiser/views/Actions/Results";
import ActionStatus from "Organiser/views/Actions/Status";
import ActionTaker from "Organiser/views/Actions/Taker";
import ActionBegin from "Organiser/views/Actions/Begin";
import ActionSurvey from "Organiser/views/Actions/Survey";
import ActionComplete from "Organiser/views/Actions/Complete";
import ActionList from "Organiser/views/Actions/List";

// live dashboards
import RepDashboardList from "Organiser/views/RepDashboards/List";
import RepDashboardBuild from "Organiser/views/RepDashboards/Build";

// users
import UsersView from "Organiser/views/Users/View";

// Admin
import AdminRoutes from 'Organiser/AdminRoutes'

import useCurrentUser from "Organiser/useCurrentUser";

const Routes = () => {
  const act = useAct()

  useEffect(() => {
    act.users.loadCurrentUser()
  }, [])

  const { user, isLoadingUser } = useCurrentUser()
  const accessLevel = user?.accessLevel
  const canAdministrateChecklists = ["branch_secretary", "admin"].includes(accessLevel)
  const isActivist = ['activist', 'member'].includes(accessLevel)

  if (isLoadingUser) {
    return null
  }

  return (
    <>
      <Router>
        <PageContainer>
          <Switch>
            <Route exact path="/">
              {accessLevel === "super_admin" && <Redirect to="/admin" />}
              {accessLevel === "admin" && <Redirect to="/dashboard/admin" />}
              {isActivist && <Redirect to="/dashboard/school" />}
              {accessLevel === "branch_secretary" && (
                <Redirect to="/dashboard/branch" />
              )}
            </Route>
            <Route exact path="/login">
              <Login/>
            </Route>
            <Route exact path="/login/magic_link">
              <LoginWithMagicLink/>
            </Route>
            <Route exact path="/dashboard/admin">
              {accessLevel === "admin" && <DashboardAdmin />}
            </Route>
            <Route exact path="/dashboard/school">
              {isActivist && <DashboardActivist />}
            </Route>
            <Route exact path="/admin">
              {accessLevel === "super_admin" && <AdminRoutes />}
            </Route>
            <Route exact path="/dashboard/branch">
              {accessLevel === "branch_secretary" && <DashboardBranch />}
            </Route>
            <Route exact path="/actions">
              <ActionList />
            </Route>
            <Route exact path="/checklists">
            { canAdministrateChecklists && <ChecklistList />}
            </Route>
            <Route exact path="/checklists/new">
            { canAdministrateChecklists && <ChecklistNew />}
            </Route>
            <Route exact path="/checklists/:checklistId">
              { canAdministrateChecklists && <ChecklistShow />}
            </Route>
            <Route exact path="/checklists/:checklistId/edit">
              { canAdministrateChecklists && <ChecklistNew />}
            </Route>
            <Route exact path="/checklists/:checklistId/email">
              { canAdministrateChecklists && <ChecklistEmail />}
            </Route>
            <Route exact path="/checklists/:checklistId/begin">
              {isActivist && <ChecklistBegin />}
            </Route>
            <Route exact path="/checklists/:checklistId/actions/new">
              { canAdministrateChecklists && <ActionBuild />}
            </Route>
            <Route exact path="/checklists/:checklistId/actions/:actionId/edit">
              { canAdministrateChecklists && <ActionBuild />}
            </Route>
            <Route exact path="/checklists/:checklistId/actions/:actionId/begin">
              { (canAdministrateChecklists || isActivist) && <ActionBegin />}
            </Route>
            <Route exact path="/checklists/:checklistId/actions/:actionId/results">
              { canAdministrateChecklists && <ActionResults />}
            </Route>
            <Route exact path="/checklists/:checklistId/actions/:actionId/status">
              <ActionStatus />
            </Route>
            <Route exact path="/checklists/:checklistId/actions/:actionId">
              { (canAdministrateChecklists || isActivist)  && <ActionSurvey />}
            </Route>
            <Route
              exact
              path="/checklists/:checklistId/actions/:actionId/complete/:actionTakerId"
            >
              {(canAdministrateChecklists || isActivist) && <ActionComplete />}
            </Route>
            <Route exact path="/action_takers/:actionTakerId">
              <ActionTaker />
            </Route>
            <Route exact path="/rep_dashboards">
              <RepDashboardList />
            </Route>
            <Route exact path="/rep_dashboards/:dashboardId">
              <RepDashboardBuild />
            </Route>
            <Route exact path="/users/:userId">
              <UsersView />
            </Route>
          </Switch>
        </PageContainer>
      </Router>
    </>
  );
};

export default Routes;
