const ADMIN_MANDATE_LABELS = {
  'branch': 'Sent from your branch',
  'region': 'Sent from your region',
  'national': 'Sent from your union'
}

const MANDATE_LABELS = {
  'branch': 'Sent from your NEU Branch',
  'region': 'Sent from your regional NEU Branch',
  'national': 'Sent from your union'
}

export { ADMIN_MANDATE_LABELS, MANDATE_LABELS }