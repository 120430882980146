import React, { useState, useMemo } from 'react'
import Async, { useAsync } from 'react-select/async'
import axios from 'axios'
import _ from 'lodash'

export default function MovementSearchSelect({ onChange, value }) {
  const [options, setOptions] = useState([])

  const dLoadOptions = useMemo(() => {
    var initialLoad = true

    function loadOptions(query, callback) {
      function handleOptions(data) {
        const options = data.map(({ value, label }) => {
          return { value, label }
        })
        setOptions(options)

        return callback(options)
      }

      if (value && initialLoad) {
        axios.get('/api/movement/search', { params: { id: value } })
        .then(({ data }) => {
          handleOptions([{ value: data.id, label: data.name }])
        })
        initialLoad = false
        return
      }

      axios.get('/api/movement/searches', { params: { query } })
      .then(({ data }) => handleOptions(data))
    };

    return _.debounce(loadOptions, 250)
  }, [])

  function handleChange(option) {
    if (!_.find(options, o => o.id === option.value)) {
      setOptions([...options, option])
    } // This is a new one
    onChange(option.value)
  }

  return <div>
    <Async
      cacheOptions
      loadOptions={dLoadOptions}
      onChange={handleChange}
      defaultOptions
      value={_.find(options, o => o.value === value)}
    />
  </div>
}



