export default function ReminderTimes({ times, callback }) {
  const [showInterface, setShowInterface] = useState(times.length > 0)

  return (
    <div>
      <p>Enter a list of reminders to send, with 1 per line. Each number is the number of days after the previous reminder that the next will be sent.</p>
      <p className='small smaller'>e.g: if you enter 1, 3 and 7, the first reminder is sent after 1 day. The next is sent 3 days after that. The last one is sent 7 days later. In total, these will take 11 days to send.</p>
      { showInterface && <textarea
        value={times.join("\n")}
        rows={3}
        onChange={(e) => callback(e.target.value || ''.split("\n")) } /> }
      { !showInterface && <a className='blue-link' onClick={() => setShowInterface(true)}>Customise reminder times</a> }
    </div>
  )
}
