import * as React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  Show,
  SimpleForm,
  DateField,
  TextField,
  BooleanField,
  UrlField,
  SelectInput,
  EditButton,
  TextInput,
  DateInput,
  BooleanInput,
  ShowButton,
  SimpleShowLayout,
  required
} from "react-admin";

const MasqueradeButton = ({ record }) => {
  function handleClick() {
    fetch(`/admin_api/users/${record.id}/new_masquerade_path`)
      .then(response => response.json())
      .then(data => (window.location = data.path))
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <button type="button" onClick={handleClick}>
      Masquerade
    </button>
  );
};

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="email" validate={required()} />
      <TextInput source="password" validate={required()} />
      <SelectInput source="access_level" validate={required()} choices={[
          { id: 'admin', name: 'National Admin' }
      ]} />
      <div>User will be assigned to National organisation</div>
    </SimpleForm>
  </Create>
);

const filters = [
  <TextInput label="Search" source="q" alwaysOn />
]

export const UserList = props => {
  return (
    <List filters={filters} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <BooleanField source="enable_forums" label="Enable Forums" />
        <BooleanField source="is_forum_moderator" label="Moderator" />
        <DateField source="created_at" />
        <ShowButton basePath="/users" />
        <EditButton basePath="/users" />
        <MasqueradeButton />
      </Datagrid>
    </List>
  );
};

export const UserShow = (props) => (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <BooleanField source="enable_forums" label="Enable Forums" />
        <BooleanField source="is_forum_moderator" label="Moderator" />
        <DateField source="created_at" />
        <UrlField source="magic_link" />
        <MasqueradeButton />
      </SimpleShowLayout>
    </Show>
);

export const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="name" />
      <BooleanInput source="enable_forums" label="Enable Forums" />
      <BooleanInput source="is_forum_moderator" label="Moderator" />
    </SimpleForm>
  </Edit>
);
