import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import { validate as isUuid } from 'uuid'

const csrfToken = (document?.querySelector("meta[name=csrf-token]"))?.content
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

const restClient = applyCaseMiddleware(axios.create(), {
  preservedKeys: (key) => {
    return isUuid(key)
  }
})

export default restClient