import Table from "Organiser/components/DataTable/Table"

export default function ActionList() {
  return (
    <div>
      <h2>Actions I've taken</h2>
      <p className='bold'>Below is a list of actions you've taken. You can see details about them, and the answers you gave.</p>
      <Table table={'actions'} />
    </div>
  );
};
