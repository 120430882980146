import { useEffect } from 'react';
import './OrganisationSelector.scoped.scss'

const childLabel = {
  national: 'Regions',
  region: 'Local authorities',
  local_authority: 'Branches',
  branch: 'Schools'
}

const Organisation = ({ organisationId, depth = 0, mode, actionId }) => {
  const act = useAct()
  const organisation = useSelector(s => s.organisation.organisations[organisationId])
  const children = useSelector(s => s.organisation.children[organisationId] || [])
  const selected = useSelector(s => s.organisation.selected[organisationId] || false)
  const expanded = useSelector(s => s.organisation.expanded[organisationId] || false)
  const canExpand = organisation && !expanded && childLabel[organisation.kind]
  const showCheckboxes = mode !== 'stats'
  const showStats = mode === 'stats'

  if (!organisation) {
    return null
  }

  function expand() {
    act.organisation.expand(organisationId, true);
    if (children.length === 0) {
      act.organisation.loadChildren(organisationId, mode === 'stats' ? actionId : null).then(() => {
        // if we're creating permissions, we'll assume all children mimic the parent one
        if (mode === 'creating' && selected) {
          act.organisation.toggle(organisationId, true)
        }
      });
    }
  }

  function close() {
    act.organisation.expand(organisationId, false)
  }

  function toggle() {
    act.organisation.toggle(organisationId)
  }

  return (
    <div className={`organisation ${depth > 0 ? 'depth' : ''}`}>
      <div className='selector'>
        <label className='name'>
          { showCheckboxes && <input type='checkbox' onChange={toggle} checked={selected} /> }
          { organisation.name } <span className='type'>{organisation.kind.replace('_', ' ')}</span>
        </label>
        { showStats && <div className='stats'>
          <p className='number'>{organisation.started || 0} started</p>
          <p className='number'>{organisation.completed || 0} completed</p>
        </div> }
        { expanded && <a className='button smallest' onClick={close}>Hide {childLabel[organisation.kind]}</a> }
        { canExpand && <a className='button smallest' onClick={expand}>Show {childLabel[organisation.kind]}</a> }
      </div>
      { expanded && 
        <div className='children'>
          { children.map((childId) => { 
            return <Organisation key={childId} mode={mode} actionId={actionId} organisationId={childId} depth={depth + 1} />
          } )}
          { children.length === 0 && <p>Loading...</p> }
        </div>
      }
    </div>
  );
};

export default function OrganisationSelector({ mode = 'editing', actionId }) {
  const act = useAct()
  const overrideOrganisationId = useSelector(s => s.local.organisationId || 'national')
  const nationalOrganisationId = useSelector(s => s.local.organisationId || s.organisation.nationalOrganisationId)

  function load(nationalId) {
    act.organisation.loadChildren(nationalId, mode === 'stats' ? actionId : null).then((response) => {
      const nationalOrganisationId = response.data.organisation.id
      act.organisation.setNationalOrganisationId(nationalOrganisationId)
      act.organisation.expand(nationalOrganisationId, true)

      // if we're creating a new selection list, pre-select the top level org
      if (mode === 'creating') {
        act.organisation.toggle(nationalOrganisationId)
      }
    })
  }

  useEffect(() => {
    load(overrideOrganisationId)
    return act.organisation.reset
  }, [overrideOrganisationId])

  return (
    <div className='organisation-selector'>
      <Organisation organisationId={nationalOrganisationId} mode={mode} actionId={actionId} />
    </div>
  );
};
