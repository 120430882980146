import { useParams, useHistory } from "react-router-dom"
import useCurrentUser from "Organiser/useCurrentUser"
import { MANDATE_LABELS } from 'Organiser/constants'
import RightImage from "../../../../images/right.png"

export default function Begin() {
  const act = useAct()
  const history = useHistory()
  const { checklistId, actionId } = useParams()
  const { user } = useCurrentUser()
  const action = useEager("actions", actionId)
  const checklist = useEager("checklists", checklistId)
  const questions = useSelector(s => _.filter(_.values(s.questions.entities), { actionId: parseInt(action.id) }))
  const hasQuestions = questions.length > 0
  const fakeResponseMode = useSelector(s => s.local.fakeResponseMode)

  useEffect(() => {
    act.checklists.load(checklistId, null, true)
  }, [checklistId])

  function begin() {
    act.action.beginAnswering({ checklistId, actionId: action.id }).then(() => {
      history.push(`/checklists/${checklist.id}/actions/${action.id}`)
    })
  }

  if (!user || !checklist.id || !action) {
    return null
  }

  return (
    <div>
      <h2>
        {checklist.name}<br/>
        <span className='dark-grey'>{action.name}</span>
      </h2>
      { fakeResponseMode && <div className='alert'>Warning: You are testing this survey and responses will not be saved.</div> }
      <p className='no-margin-top italic'>{MANDATE_LABELS[checklist.mandatedBy]}</p>
      <div className="flex space-x-2">
        <div className="w-1/2 box p-4">
          <h3 className="no-margin-top">Overview</h3>
          <div dangerouslySetInnerHTML={{ __html: urlify(action.description) }} />
        </div>
        <div className="w-1/2 box p-4 margin-top-mobile">
          { hasQuestions && <p className='bold no-margin-top'><img className='icon' src={RightImage} /> When you're ready, hit Begin to take the survey.</p> }
          { !hasQuestions && <p className='bold no-margin-top'><img className='icon' src={RightImage} /> Once you've completed this action, press Complete to let us know.</p> }
          <button className="button primary js-begin-checklist" onClick={begin}>
            { hasQuestions ? 'Begin' : 'Complete' }
          </button>
        </div>
      </div>
    </div>
  )
}
