import ServerWaitButton from 'Organiser/components/ServerWaitButton'
import { Link } from 'react-router-dom'
import { ADMIN_MANDATE_LABELS } from 'Organiser/constants'
import RocketImage from "../../../../../images/rocket.png"
import EmailImage from "../../../../../images/email.png"

export default function ChecklistHeader({ canAdministrate, checklistId }) {
  const act = useAct()
  const history = useHistory()
  const checklist = useEager('checklists', checklistId)
  const actions = useSelector(s => _.values(s.actions.entities).filter(a => a.checklistId === parseInt(checklistId)))
  const [confirmPublish, setConfirmPublish] = useState(false)
  const [confirmClose, setConfirmClose] = useState(false)
  const [confirmArchive, setConfirmArchive] = useState(false)
  const canEdit = !_.includes(['assigning', 'updating', 'launching', 'closed'], checklist.state)
  const canGoLive = checklist.state === 'draft' && actions.length > 0
  const canEmail = checklist.state === 'live'
  const canClose = checklist.state === 'live'
  const canArchive = checklist.state === 'closed' || checklist.state === 'draft'

  function goLive() {
    return act.checklist.goLive(checklistId).then((response) => {
      setConfirmPublish(false);
      return response;
    })
  }

  function close() {
    return act.checklist.close(checklistId).then((response) => {
      setConfirmClose(false);
      return response;
    })
  }

  function archive() {
    return act.checklist.archive(checklistId).then((response) => {
      history.push(
        `/checklists`
      )
    })
  }

  function duplicate() {
    return act.checklist.duplicate(checklistId).then((response) => {
      history.push(
        `/checklists/${response.data.id}`
      )
    })
  }

  return (
    <>
      <div className='title'>
        <h2>
          {checklist.name}
          <div className='ellipsis wider' />
          <span className='dark-grey'>
            {checklist.state === 'live' && <img src={RocketImage} className='icon' />}
            {checklist.state}
          </span>
        </h2>
        {canAdministrate && <div className='actions'>
          {canEdit && <a className="button primary" onClick={() => duplicate()}>Duplicate</a>}
          {canEdit && <Link to={`/checklists/${checklist.id}/edit`} className="button primary">Edit</Link>}
          {canGoLive && <a className="button primary" onClick={() => setConfirmPublish(true)}><img src={RocketImage} className='icon' /> <span>Publish</span></a>}
          {canEmail && <Link to={`/checklists/${checklist.id}/email`} className="button secondary"><img src={EmailImage} className='icon' /> <span>Contact Reps</span></Link>}
          {canClose && <a className="button red" onClick={() => setConfirmClose(true)}>Close</a>}
          {canArchive && <a className="button red" onClick={() => setConfirmArchive(true)}>Archive</a>}
        </div>}
      </div>
      {canAdministrate && <p className='bold no-margin-top'>Here you can edit this journey, see results, or add a new step.</p>}
      {!canAdministrate && <p className='bold no-margin-top'>Here you can email this journey to reps, or see results.</p>}
      <p className='no-margin-top italic'>{ADMIN_MANDATE_LABELS[checklist.mandatedBy]}</p>

      {confirmClose &&
        <div className='alert'>
          <p>Are you sure you want to close this checklist?</p>
          <div className='buttons margin-top'>
            <ServerWaitButton className='button smallest primary' loaderHeight={12} loaderColor={'black'} onClick={close}>Yes</ServerWaitButton>
            <a className='button smallest primary' onClick={() => setConfirmClose(false)}>No</a>
          </div>
        </div>
      }

      {confirmArchive &&
        <div className='alert'>
          <p>Are you sure you want to archive this checklist?</p>
          <div className='buttons margin-top'>
            <ServerWaitButton className='button smallest primary' loaderHeight={12} loaderColor={'black'} onClick={archive}>Yes</ServerWaitButton>
            <a className='button smallest primary' onClick={() => setConfirmArchive(false)}>No</a>
          </div>
        </div>
      }

      {confirmPublish &&
        <div className='alert'>
          <p>Are you sure you want to publish this checklist?</p>
          <div className='buttons margin-top'>
            <ServerWaitButton className='button smallest primary' loaderHeight={12} loaderColor={'black'} onClick={goLive}>Yes</ServerWaitButton>
            <a className='button smallest primary' onClick={() => setConfirmPublish(false)}>No</a>
          </div>
        </div>
      }
    </>
  )
}