import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from 'recharts'
import Loading from 'Organiser/components/Loading'
import './Question.scoped.scss'

const typeLabels = {
  'multiple_choice': 'Multiple choice',
  'multi_select': 'Multi select',
  'date': 'Date',
  'file': 'File upload',
  'text': 'Text entry'
}

const MultipleChoiceGraph = ({ data }) => {
  return <div className='split-graph'>
    <div className='graph'>
      <ResponsiveContainer height={data.length * 70}>
        <BarChart data={data} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" allowDecimals={false} />
          <YAxis type="category" width={200} dataKey="name" />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" maxBarSize={30} />
        </BarChart>
      </ResponsiveContainer>
    </div>
    <div className='results'>
      {data.map((result) => {
        return <div className='result'>
          <p className='label'>{result.name}</p>
          <p className='count'>{result.count}</p>
        </div>
      })}
    </div>
  </div>
}

const DateGraph = ({ data }) => {
  return <ResponsiveContainer height={300}>
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" tickFormatter={time => moment(time).format('DD/MM')} />
      <YAxis allowDecimals={false} />
      <Tooltip />
      <Bar dataKey="count" fill="#8884d8" />
    </BarChart>
  </ResponsiveContainer>
}

const List = ({ data, showDownload = false }) => {
  return <div className='list'>
    {data && data.map((result) => {
      return <div className='inset answer'>
        <p className='left'>{result.answer}</p>
        <p className='name'>{result.name} </p>
        <p className='organisation'>{result.organisation}</p>

        {showDownload && <a className='button smallest' href={result.fileUrl} target='_blank'>Download</a>}
      </div>
    })}
  </div>
}

export default function Question({ id }) {
  const act = useAct()
  const { checklistId, actionId } = useParams()
  const organisationId = useSelector(s => s.local.organisationId)
  const { inputType, question, questionOrder } = useSelector(s => s.questions.entities[id])
  const type = typeLabels[inputType]
  const [results, setResults] = useState(null)
  const allowQuerying = _.includes(['file', 'text'], inputType)
  const [query, setQuery] = useState('')
  const [offset, setOffset] = useState(0)
  const [loaded, setLoaded] = useState(false)

  const load = useMemo(() => {
    function load(newQuery, newOffset) {
      console.log('Loading', id)
      act.results.load(checklistId, actionId, id, newQuery, newOffset).then((response) => {
        setResults(response.data)
        setLoaded(true)
      })
    }

    return _.debounce(load, 500)
  }, [checklistId, actionId, id])

  function updateQuery(newQuery) {
    setQuery(newQuery)
    load(newQuery, offset)
  }

  function moveOffset(newOffset) {
    setOffset(newOffset)
    load(query, newOffset)
  }

  useEffect(() => {
    load('')
  }, [])

  useEffect(() => {
    if (organisationId) {
      load(query)
    }
  }, [organisationId])

  return (
    <div className='root box'>
      <h3 className='no-margin-top'>Q{questionOrder + 1}: {question} <div className='badge'>{type}</div> <span className='small smaller bold dark-grey'>ID: {id}</span> </h3>

      {allowQuerying && <div className='filter'>
        <input type='text' value={query} onChange={(e) => updateQuery(e.target.value)} placeholder='Search' />
      </div>}

      <Loading loaded={loaded}>
        {(inputType === 'multiple_choice' || inputType === 'multi_select' || inputType === 'rating') && <MultipleChoiceGraph data={results} />}
        {inputType === 'date' && <DateGraph data={results} />}
        {inputType === 'text' && <List data={results} />}
        {inputType === 'file' && <List data={results} showDownload={true} />}
        {inputType === 'instruction' && <p>This question is an instruction - there's no answers to see.</p>}
        {inputType === 'address' && <p>This question asks for addresses. You can see these when you download the results.</p>}

        {(allowQuerying && results) && <div className='pagination'>
          <button className='button smallest' disabled={offset === 0} onClick={() => moveOffset(offset - 10)}>Back</button>
          <button className='button smallest' disabled={results.length < 10} onClick={() => moveOffset(offset + 10)}>Next</button>
        </div>}
      </Loading>
    </div>
  )
}
