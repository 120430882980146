import { createSlice } from "@reduxjs/toolkit"

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    checklists: [],
    completedChecklists: [],
    loaded: false,
    mandatedBy: 'national'
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

function actions(dis, store, restClient) {
  function loadChecklists(mandatedBy = 'national', state = 'live') {
    return restClient.get(`/api/checklists?mandated_by=${mandatedBy}&state=${state}`)
      .then(response => {
        dis({
          type: "dashboard/upsert",
          payload: { checklists: response.data },
        })
        return response
      })
  }

  function loadMyChecklists() {
    return restClient.get(`/api/checklists/mine`)
      .then(response => {
        dis({
          type: "dashboard/upsert",
          payload: {
            unstartedChecklists: response.data.active,
            completedChecklists: response.data.completed,
            editableActions: response.data.editable
          },
        })
        return response
      })
  }

  function loadLiveTrackedActions() {
    return restClient.get(`/api/checklists/live_tracked`)
      .then(response => {
        dis({
          type: "dashboard/upsert",
          payload: { liveTrackedActions: response.data.actions },
        })
        return response
      })
  }

  function loadOrganisationChecklists(organisationId) {
    return restClient.get(`/api/checklists/for_organisation?organisation_id=${organisationId}`)
      .then(response => {
        dis({
          type: "dashboard/upsert",
          payload: { checklists: response.data },
        })
        return response
      })
  }

  function exportAction(actionId, since = null) {
    return restClient.post(`/api/actions/${actionId}/export${since ? `?since=${since}` : ''}`)
      .then(({ data }) => {
        const { password } = data
        dis({
          type: 'local/upsert', payload: {
            alert: {
              persist: true,
              message: `The answers for this action are being exported. You will receive an email when this is complete. Use this password to open the attached file: ${password}`
            }
          }
        })
      })
      .catch(() => {
        dis({
          type: 'local/upsert', payload: {
            alert: {
              persist: true,
              severity: 'error',
              message: `Something went wrong and the answers couldn't be exported`
            }
          }
        })
      })
  }

  function upsert(data) {
    dis({ type: `dashboard/upsert`, payload: data })
  }

  return {
    loadChecklists,
    loadMyChecklists,
    loadOrganisationChecklists,
    loadLiveTrackedActions,
    exportAction,
    upsert
  }
}

const reducer = dashboardSlice.reducer
export { actions, reducer }
