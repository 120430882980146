function actions(dis, store, restClient) {
  function getDefaultOrganisationId() {
    const state = store.getState()
    const userId = window.ENV.CURRENT_USER_ID
    const permissionId = state.users.entities[userId]?.defaultPermissionId
    const organisationId = state.permissions.entities[permissionId]?.organisationId

    return organisationId
  }

  function load(checklistId, maybeOrganisationId = null, loadQuestions = true) {
    const organisationId = maybeOrganisationId || getDefaultOrganisationId() || ''

    return restClient.get(`/api/checklists/${checklistId}?organisation_id=${organisationId}`)
      .then(({ data }) => {
        const { checklist, actions, questions } = data

        dis({ type: "checklists/upsert", payload: checklist })
        actions.forEach(a => dis({ type: "actions/upsert", payload: a }))
        if (loadQuestions) {
          questions.forEach(q => dis({ type: "questions/upsert", payload: q }))
        }
      })
  }

  function poll(checklistId, organisationId = null) {
    const interval = setInterval(() => load(checklistId, organisationId, false), 5000)
    load(checklistId, organisationId, false)

    return () => clearInterval(interval)
  }

  return { load, poll }
}

export { actions }