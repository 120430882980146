import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

export default function generateSlices(entityNames, extraReducers = {}) {
  const adapter = createEntityAdapter()

  return _.fromPairs(_.map(entityNames, name => {
    return [name, createSlice({
      name,
      initialState: adapter.getInitialState(),
      reducers: {
        upsert: adapter.upsertOne,
        upsertMany: adapter.upsertMany,
        add: adapter.addOne,
        setAll: adapter.setAll,
        remove: adapter.removeOne,
        removeMany: adapter.removeMany,
        ...(extraReducers[name] || {})
      }
    }).reducer]
  }))
}