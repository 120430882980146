// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require( 'datatables.net' )( window, $ );
require( 'datatables.net-dt' )( window, $ );

import Routes from "../bundles/Organiser/Routes";

import {
  actions as localActions,
  reducer as localReducer,
} from "../bundles/hooks/localSlice";
import {
  actions as actionActions,
  reducer as actionReducer,
} from "../bundles/hooks/actionSlice";
import {
  actions as actionEditorActions,
  reducer as actionEditorReducer,
} from "../bundles/hooks/actionEditorSlice";
import {
  actions as checklistActions,
  reducer as checklistReducer,
} from "../bundles/hooks/checklistSlice";
import {
  actions as dashboardActions,
  reducer as dashboardReducer,
} from "../bundles/hooks/dashboardSlice";
import {
  actions as dashboardsActions,
  reducer as dashboardsReducer,
} from "../bundles/hooks/dashboardsSlice";
import {
  actions as organisationActions,
  reducer as organisationReducer,
} from "../bundles/hooks/organisationSlice";
import {
  actions as scriptEditorActions,
  reducer as scriptEditorReducer,
} from "../bundles/hooks/scriptEditorSlice";
import {
  actions as emailActions,
  reducer as emailReducer,
} from "../bundles/hooks/emailSlice";
import {
  actions as magicLinkActions,
  reducer as magicLinkReducer,
} from "../bundles/hooks/magicLinkSlice";
import { actions as userActions } from 'hooks/userActions'
import { actions as checklistsActions } from 'hooks/checklistsActions'
import { actions as organisationsActions } from 'hooks/organisationsActions'
import { actions as actionResponsesActions } from 'hooks/actionResponsesActions'
import { actions as actionsActions } from 'hooks/actionsActions'
import { actions as checklistUsersActions } from 'hooks/checklistUsersActions'
import { actions as resultsActions } from 'hooks/resultsActions'
import { actions as adminStatisticsActions } from 'hooks/adminStatisticsActions'
import { actions as actionStatusesActions } from 'hooks/actionStatusesActions'

import entityNames from 'hooks/entityNames'
import extraReducers from 'hooks/extraReducers'
import createEntityReducers from 'hooks/createEntityReducers'

import ReduxProvider from 'hooks/ReduxProvider'

document.addEventListener("DOMContentLoaded", () => {
  function createActions(dispatch, store, restClient, act) {
    return {
      action: actionActions(store.dispatch, store, restClient),
      actionEditor: actionEditorActions(store.dispatch, store, restClient),
      checklist: checklistActions(store.dispatch, store, restClient),
      dashboard: dashboardActions(store.dispatch, store, restClient),
      dashboards: dashboardsActions(store.dispatch, store, restClient),
      organisation: organisationActions(store.dispatch, store, restClient),
      scriptEditor: scriptEditorActions(store.dispatch, store, restClient),
      local: localActions(store.dispatch, store, restClient),
      email: emailActions(store.dispatch, store, restClient),
      magicLink: magicLinkActions(store.dispatch, store, restClient),
      users: userActions(store.dispatch, store, restClient),
      checklists: checklistsActions(store.dispatch, store, restClient),
      organisations: organisationsActions(store.dispatch, store, restClient),
      actionResponses: actionResponsesActions(store.dispatch, store, restClient),
      actions: actionsActions(store.dispatch, store, restClient),
      checklistUsers: checklistUsersActions(store.dispatch, store, restClient),
      adminStatistics: adminStatisticsActions(store.dispatch, store, restClient),
      actionStatuses: actionStatusesActions(store.dispatch, store, restClient),
      results: resultsActions(store.dispatch, store, restClient)
    };
  }

  const reducers = {
    ...createEntityReducers(entityNames, extraReducers),
    action: actionReducer,
    actionEditor: actionEditorReducer,
    checklist: checklistReducer,
    dashboard: dashboardReducer,
    dashboards: dashboardsReducer,
    organisation: organisationReducer,
    scriptEditor: scriptEditorReducer,
    local: localReducer,
    email: emailReducer
  };

  ReactDOM.render(
    <ReduxProvider
      extraActions={createActions}
      reducers={reducers}
    >
      <Routes />
    </ReduxProvider>,
    document.getElementById("react-app")
  );
});
