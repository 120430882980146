import Table from "Organiser/components/DataTable/Table"
import useCurrentUser from "Organiser/useCurrentUser"

export default function ChecklistList() {
  const { user } = useCurrentUser()
  const accessLevel = user?.accessLevel
  const canCreateChecklists = ["admin", "national"].includes(accessLevel)
  const term = canCreateChecklists ? 'Journeys' : 'Surveys'

  return (
    <div>
      <h2>{term}</h2>
       { canCreateChecklists && <p className='bold'>Find all national, regional, and branch journeys here.</p> }
      <Table table={'checklists'} />
    </div>
  );
};
