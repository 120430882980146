import {
  createEntityAdapter,
  createSlice,
  createReducer,
} from "@reduxjs/toolkit";
import createHttpClient from "Organiser/createHttpClient";

const adapter = createEntityAdapter();

const localSlice = createSlice({
  name: "local",
  initialState: {
    organisationId: null,
    redirectToChecklistId: null,
    showSidebar: null,
    fakeResponseMode: false
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
});

function actions(dis, store, restClient) {
  function upsert(payload) {
    dis({ type: 'local/upsert', payload })
  }

  function setSnackbar(message, options = { severity: 'info' }) {
    const { severity } = options
    upsert({ alert: { message, severity } })
  }

  return { upsert, setSnackbar };
}

const reducer = localSlice.reducer;
export { actions, reducer };
