const extraReducers = {
  questions: {
    enforceOrder: (s,a) => {
      const { actionId } = a.payload
      const questions = _.orderBy(_.filter(_.values(s.entities), { actionId }), 'questionOrder')

      questions.forEach(({ id }, i) => {
        s.entities[id].questionOrder = i
      })
    },
    moveOrder: (s,a) => {
      const { id, newOrder } = a.payload
      const actionId = s.entities[id].actionId
      const questionIds = _.orderBy(_.filter(_.values(s.entities), { actionId }), 'questionOrder').map(q => q.id)

      const newQuestionIds = _.filter(questionIds, qId => qId !== id)
      newQuestionIds.splice(newOrder, 0, id)

      newQuestionIds.forEach((id, i) => {
        s.entities[id].questionOrder = i
      })
    }
  },
  answers: {
    enforceOrder: (s,a) => {
      const { questionId } = a.payload
      const answers = _.orderBy(_.filter(_.values(s.entities), { questionId }), 'answerOrder')

      answers.forEach(({ id }, i) => {
        s.entities[id].answerOrder = i
      })
    },
    moveOrder: (s,a) => {
      const { id, newOrder } = a.payload
      const questionId = s.entities[id].questionId
      const answerIds = _.orderBy(_.filter(_.values(s.entities), { questionId }), 'answerOrder').map(q => q.id)

      const newAnswerIds = _.filter(answerIds, aId => aId !== id)
      newAnswerIds.splice(newOrder, 0, id)

      console.log({ answerIds, newAnswerIds })

      newAnswerIds.forEach((id, i) => {
        s.entities[id].answerOrder = i
      })
    }
  }
}

export default extraReducers