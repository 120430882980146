import _ from "lodash"
import Loading from "Organiser/components/Loading"
import Question from "./Question"
import { useSelector } from "react-redux"

export default function Answers({ actionId }) {
  const questions = useSelector((s) =>
    _.orderBy(
      _.filter(s.questions.entities, { actionId: parseInt(actionId) }),
      ["questionOrder"],
      ["asc"]
    )
  )

  return (
    <div>
      {questions.map((question) => (
        <Question key={question.id} id={question.id} />
      ))}
    </div>
  )
}
